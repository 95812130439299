import React, { useContext } from 'react';

const ResponsiveContext = React.createContext();

ResponsiveContext.displayName = 'ResponsiveContext';

export const ResponsiveProvider = ResponsiveContext.Provider;
export const ResponsiveConsumer = ResponsiveContext.Consumer;

export const withResponsiveContext = (Component) =>
  class Wrapper extends React.Component {
    static async getInitialProps(ctx) {
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      return { ...pageProps };
    }

    render() {
      return <ResponsiveConsumer>{(currentUser) => <Component {...this.props} {...currentUser} />}</ResponsiveConsumer>;
    }
  };

export default ResponsiveContext;

export const useResponsive = () => useContext(ResponsiveContext);
