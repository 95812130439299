import { valueMapping } from './constants';
import { assertUnionType } from 'graphql';
import { isJobTrackEnable } from '/lib/helpers';

import nookies from 'nookies';
import qs from 'qs';
import { getFilterLanguage } from '/imports/job-tracking/api/helper.js';
import env from '/env';

//boards api start here
export const getBoards = async (regionCode, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board?regionCode=${regionCode}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const changeColumn = async (obj, boardId, columnId, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}/${columnId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateBoard = async (boardName, boardId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const createNewBoard = async (boardName, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteBoard = async (id, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

//jobs api start here
export const addJobTrackingData = async (fields, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  [('createdAt', '_id', 'tag')].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteJobTrackingData = async (id, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${id}?type=jobId`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateJobTrackPosition = async (token, jobId, boardId, position, controller = {}) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ position }),
    ...controller,
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJobTrackingData = async (updatedFields, jobId, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  const fields = { ...updatedFields };
  [
    'updatedAt',
    'createdAt',
    'userId',
    'jobId',
    'notes',
    'boardId',
    'comments',
    'tasks',
    'timelines',
    'contacts',
    'zipCode',
    'contractCategories',
    'createdBy',
    'hasPendingTask',
  ].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getJobAdditionalInfo = async (jobId, boardId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// search and feeds elastic search
export const esQuery = async (query, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  };
  const response = await fetch(`${env.BASE_API}/es/query`, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

function parseSearchJobResponse(res, jobProvider) {
  return res.map((jobItem) => {
    const { salary, ...job } = jobItem;
    return {
      ...job,
      id: job?.id || job?.job_id || job?.jobId,
      title: job?.title || job?.job_title,
      location: job?.location || `${job?.job_city} ${job?.job_state} ${job?.job_country}`,
      description: job?.description || job?.job_description,
      company: job?.company || job?.company_name || job?.employer_name,
      employmentType: job?.employmentType || job?.employement_type,
      datePosted: job?.datePosted || job?.formatted_relative_time,
      salaryRange: job?.salaryRange || `${job?.salary?.max}-${job?.salary?.min} ${job?.salary?.type}`,
      portal: job?.portal || (jobProvider === 'search-v3' && 'indeed') || job?.apply_options?.[0]?.publisher,
      apiProvider: jobProvider,
      url: job?.url || job?.apply_options?.[0]?.apply_link,
    };
  });
}

const searchJobApiVersionConfig = [
  { key: 'title', params: 'title', api: ['search-v2', 'search-v3', 'search-v4'] },
  { key: 'location', params: 'where', api: ['search-v2', 'search-v3'] },
  { key: 'datePosted', params: 'postedDate', api: ['search-v2', 'search-v3'] },
  { key: 'remoteOnly', params: 'remoteOnly', api: ['search-v2'] },
  { key: 'index', api: ['search-v2', 'search-v3'], defaultValue: 0 },
  { key: 'employmentTypes', params: 'jobType', api: ['search-v2'] },
  { key: 'jobType', params: 'jobType', api: ['search-v3'] },
  { key: 'locality', params: 'locality', api: ['search-v3'], defaultValue: 'fr' },
  { key: 'language', params: 'language', api: ['search-v2'] },
  { key: 'distance', params: 'distance', api: ['search-v2'] },
  { key: 'radius', params: 'distance', api: ['search-v3', 'search-v4'] },
  { key: 'allowedJobProviders', params: 'allowedJobProviders', api: ['search-v2'] },
  { key: 'page', api: ['search-v4'], defaultValue: 1 },
  { key: 'num_pages', api: ['search-v4'], defaultValue: 10 },
  { key: 'date_posted', params: 'postedDate', api: ['search-v4'], defaultValue: 'all' },
  { key: 'markup_job_description', api: ['search-v4'], defaultValue: true },
  { key: 'employment_types', params: 'jobType', api: ['search-v4'], defaultValue: '' },
  { key: 'remote_jobs_only', params: 'remoteOnly', api: ['search-v4'] },
  { key: 'job_requirements', api: ['search-v4'] },
  { key: 'actively_hiring', api: ['search-v4'], defaultValue: true },
  {
    key: 'fields',
    api: ['search-v4'],
    defaultValue:
      'job_city,job_country,job_state,employer_name,job_publisher,job_title,job_country,job_description,employer_website,job_posted_at_datetime_utc,job_employment_type,job_is_remote,apply_options',
  },
];

function filterParamsByApi(apiVersion, isGuest, apiProvider) {
  if (isGuest || !apiProvider.includes(apiVersion)) {
    apiVersion = 'search-v2';
  }
  return searchJobApiVersionConfig.filter((item) => item.api.includes(apiVersion));
}

export const getIndeedJobDetails = async ({ jobId, company }, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ jobId }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/job-details`, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getSearchJob = async (data, token, index, podioUserId) => {
  const activeApiProviderEndpoint = ['search-v2', 'search-v3', 'search-v4'];
  const { agent_client_id } = nookies.get({});
  const isGuest = !token && isJobTrackEnable();
  const postData = filterParamsByApi(data.apiProvider, isGuest, activeApiProviderEndpoint).reduce((acc, param) => {
    let value;
    if (param.key === 'title' && data.apiProvider === 'search-v4') {
      value = `${data.title} ${data.where} ${getFilterLanguage(data.language) || 'EN'}`;
    } else if (param.key === 'language' && data?.language) {
      value = getFilterLanguage(data.language);
    } else if (['jobType', 'employmentTypes', 'employment_types'].includes(param.key) && data?.jobType?.length) {
      value = data.jobType[0];
    } else if (param.key === 'allowedJobProviders' && data?.allowedJobProviders?.length > 0) {
      value = data.allowedJobProviders;
    } else if (param.key === 'remoteOnly') {
      value = data?.remote ? data.remote !== 'On Site' : false;
    } else {
      value = data?.[param.params];
    }

    if (param?.defaultValue && !value) {
      value = param.defaultValue;
    }

    if (['page', 'index', 'num_pages'].includes(param.key)) {
      value = index || param.defaultValue;
    }

    if (value !== undefined) {
      acc[param.key] = value !== undefined ? value : '';
    }

    return acc;
  }, {});
  const guestId = localStorage.getItem('guestId');
  const endpoint = activeApiProviderEndpoint.includes(data?.apiProvider) ? data.apiProvider : 'search-v2';
  delete data.apiProvider;
  if (!postData.allowedJobProviders) {
    delete data.allowedJobProviders;
  }

  const API = isGuest ? `/job/guest-search?guestId=${guestId}` : `/job/${endpoint}?${qs.stringify(postData)}`;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (endpoint === 'search-v2') {
    fetchOptions.body = JSON.stringify(postData);
  }
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}${API}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  const jsonResponse = await response.json();
  return parseSearchJobResponse(jsonResponse, endpoint || 'search-v2');
};

export const listAgentUser = async (queryData) => {
  const queryParams = qs.stringify(queryData);
  let url = `${env.JOB_TRACK_API}/agent-user/list?${queryParams}`;
  const res = await fetch(url);
  return res.json();
};

export const getSearchData = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/filters`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// task api start here
export const getTasks = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getTaskByCursor = async (taskId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (taskId === 0) URL = `${env.JOB_TRACK_API}/task/?limit=5`;
  else URL = `${env.JOB_TRACK_API}/task/?limit=5&cursor=${taskId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const taskOperations = async (data, id, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tasks: data }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/tasks/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const multipleDeleteTask = async (taskIds, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(taskIds),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};
// contacts api start here
export const getContacts = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const addContact = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteContact = async (contactId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${contactId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateContact = async (data, id, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// comments api

export const addComment = async (comment, jobId, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referenceId: jobId,
      type: 'JOB',
      text: comment,
    }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getComments = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/comment/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/comment/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateComment = async (comment, commentId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: comment }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteComment = async (commentId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// dashboard api

export const getDashboardTimeline = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/timeline/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/timeline/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// jobtrack active api

export const activeJobTrack = async (token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/podio/activate-job-tracking`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// Calendar Api

export const getTaskByRange = async (fromDate, toDate, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/calendar?fromDate=${fromDate}&toDate=${toDate}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.json();
    throw new Error(text);
  }
  return response.json();
};

// Meeting Api

export const createMeeting = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetings = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetingById = async (meetingId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateMeeting = async (data, meetingId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteMeeting = async (meetingId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// Reminder api

export const createReminder = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/reminder`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const searchAutoComplete = async (title, type, languageCode, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return [];
};

export const cityDefaultSuggestion = async (lat, lon, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  // return [];
  const response = await fetch(`${env.JOB_TRACK_API}/job/default-suggestion?lat=${lat}&lon=${lon}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getBoardList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getActiveBoard = async (boardId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const userActivity = async (token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/user/track-activity`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getOnecenterTitleList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/title-list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJob = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/update`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
// feeds api start here
export const getFeeds = async (token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedsById = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const saveFeed = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteFeed = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updatFeedApi = async (data, id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const resetNewJobCount = async (feedId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${feedId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedList = async (country, token) => {
  const { agent_client_id } = nookies.get({});
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed?regionCode=${country}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const savedJobsOnPodio = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const listPodioSavedJobs = async (token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/agent-user/list-job`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updatePodioJob = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job-status`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const defaultCandidateSuggesiton = async (token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/candidate-search`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// SA APIS

export const markResumeFinish = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/sa-mark-finished`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return true;
};

export const generateJobSearchTemplate = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/sa-questionnaire/generate-job-search-template`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const markResumeAsChecked = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/review-job-resumes`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const markResumeAsV1 = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/mark-resume-version`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
