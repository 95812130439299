import gql from 'graphql-tag';

import { allBlockFields, userFull } from '/imports/core/api/apollo/client/fragments';
import { resumeFull, coverLetterFull } from '/imports/generator/api/apollo/client/fragments';

export const ADD_BLOCK = gql`
  mutation AddBlock($resumeId: ID!, $blockType: BlockType!, $animationKey: ID!, $heights: Heights, $variant: String) {
    addBlock(
      resumeId: $resumeId
      blockType: $blockType
      animationKey: $animationKey
      heights: $heights
      variant: $variant
    ) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const ADD_BLOCK_ITEM = gql`
  mutation AddBlockItem($resumeId: ID!, $blockId: ID!, $animationKey: ID!) {
    addBlockItem(resumeId: $resumeId, blockId: $blockId, animationKey: $animationKey) {
      resumeId
      blockId
      itemId
      items {
        id
        order
        animationKey
        fields {
          ...AllBlockFields
        }
      }
    }
  }
  ${allBlockFields}
`;

export const ADD_COVER_LETTER_BLOCK_ITEM = gql`
  mutation AddCoverLetterBlockItem($id: ID!, $blockId: ID!, $animationKey: ID!) {
    addBlockItem(id: $id, blockId: $blockId, animationKey: $animationKey) {
      id
      blockId
      itemId
      items {
        id
        order
        animationKey
        fields {
          ...AllBlockFields
        }
      }
    }
  }
  ${allBlockFields}
`;

export const UPDATE_RESUME_DETAIL = gql`
  mutation UpdateResumeDetail(
    $docId: ID!
    $path: String!
    $value: Value!
    $checkForLinkedin: Boolean
    $heights: Heights
    $variant: String
  ) {
    updateResumeDetail(
      docId: $docId
      path: $path
      value: $value
      checkForLinkedin: $checkForLinkedin
      heights: $heights
      variant: $variant
    ) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_COVER_LETTER_DETAIL = gql`
  mutation UpdateCoverLetterDetail($docId: ID!, $path: String!, $value: Value!) {
    updateDetail(docId: $docId, path: $path, value: $value) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const UPDATE_WIZARD_STEPS = gql`
  mutation UpdateWizardSteps($docId: ID!, $value: String!, $heights: Heights, $variant: String) {
    updateWizardSteps(docId: $docId, value: $value, heights: $heights, variant: $variant) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_BLOCK_FIELD = gql`
  mutation UpdateBlockField($docId: ID!, $blockId: ID!, $field: String!, $value: Value!) {
    updateBlockField(docId: $docId, blockId: $blockId, field: $field, value: $value) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_BLOCK_ITEM_FIELD = gql`
  mutation UpdateBlockItemField(
    $docId: ID!
    $blockId: ID!
    $itemId: ID!
    $field: String!
    $value: Value!
    $variant: String
    $setting: ResumeSettingsInput
  ) {
    updateBlockItemField(
      docId: $docId
      blockId: $blockId
      itemId: $itemId
      field: $field
      value: $value
      variant: $variant
      setting: $setting
    ) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_COVER_LETTER_BLOCK_ITEM_FIELD = gql`
  mutation UpdateCoverLetterBlockItemField($docId: ID!, $blockId: ID!, $itemId: ID!, $field: String!, $value: Value!) {
    updateBlockItemField(docId: $docId, blockId: $blockId, itemId: $itemId, field: $field, value: $value) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const REORDER_BLOCK = gql`
  mutation ReorderBlock(
    $resumeId: ID!
    $blockId: ID!
    $direction: ReorderDirection!
    $position: [Int]
    $field: String
    $value: Value
  ) {
    reorderBlock(
      resumeId: $resumeId
      blockId: $blockId
      direction: $direction
      position: $position
      field: $field
      value: $value
    ) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REMOVE_BLOCK = gql`
  mutation RemoveBlock($resumeId: ID!, $blockId: ID!, $heights: Heights, $variant: String) {
    removeBlock(resumeId: $resumeId, blockId: $blockId, heights: $heights, variant: $variant) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REORDER_BLOCK_ITEM = gql`
  mutation ReorderBlockItem($resumeId: ID!, $blockId: ID!, $itemId: ID!, $direction: ReorderDirection!) {
    reorderBlockItem(resumeId: $resumeId, blockId: $blockId, itemId: $itemId, direction: $direction) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REORDER_BLOCK_ITEM_WITH_DRAG = gql`
  mutation reorderBlockItemsWithDrag($resumeId: ID!, $blockId: ID!, $itemId: ID!, $order: Int!) {
    reorderBlockItemsWithDrag(resumeId: $resumeId, blockId: $blockId, itemId: $itemId, order: $order) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REORDER_COVER_LETTER_BLOCK_ITEM = gql`
  mutation ReorderCoverLetterBlockItem($id: ID!, $blockId: ID!, $itemId: ID!, $direction: ReorderDirection!) {
    reorderBlockItem(id: $id, blockId: $blockId, itemId: $itemId, direction: $direction) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const REMOVE_BLOCK_ITEM = gql`
  mutation RemoveBlockItem($resumeId: ID!, $blockId: ID!, $itemId: ID!) {
    removeBlockItem(resumeId: $resumeId, blockId: $blockId, itemId: $itemId) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REMOVE_COVER_LETTER_BLOCK_ITEM = gql`
  mutation RemoveCoverLetterBlockItem($id: ID!, $blockId: ID!, $itemId: ID!) {
    removeBlockItem(id: $id, blockId: $blockId, itemId: $itemId) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: String!) {
    uploadImage(image: $image)
  }
`;

export const UPLOAD_IMAGE_RESUME = gql`
  mutation UploadResumeImage($image: String!, $resumeId: ID!) {
    uploadResumeImage(image: $image, resumeId: $resumeId)
  }
`;

export const GET_DOWNLOAD_LINK = gql`
  mutation GetDownloadLink($resumeId: ID!) {
    getDownloadLink(resumeId: $resumeId) {
      downloadKey
      resumeId
    }
  }
`;

export const UPDATE_AUTOSUGGEST = gql`
  mutation UpdateAutosuggest(
    $resumeId: ID!
    $city: String
    $country: String
    $countryCode: String
    $zip: String
    $address: String
  ) {
    updateAutosuggest(
      resumeId: $resumeId
      city: $city
      country: $country
      countryCode: $countryCode
      zip: $zip
      address: $address
    ) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_COVER_LETTER_AUTOSUGGEST = gql`
  mutation UpdateCoverLetterAutosuggest(
    $id: ID!
    $city: String
    $country: String
    $zip: String
    $address: String
    $isCompany: Boolean
  ) {
    updateAutosuggest(id: $id, city: $city, country: $country, zip: $zip, address: $address, isCompany: $isCompany) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const UPDATE_AUTOSUGGEST_SCHOOL = gql`
  mutation UpdateAutosuggestSchools($resumeId: ID!, $itemId: ID!, $location: String, $school: String) {
    updateAutosuggestSchool(resumeId: $resumeId, itemId: $itemId, location: $location, school: $school) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const UPDATE_BLOCK_POSITION = gql`
  mutation updateBlocksPosition($resumeId: ID!, $blocks: [BlockPositionInput]) {
    updateBlocksPosition(resumeId: $resumeId, blocks: $blocks) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;
