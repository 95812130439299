import gql from 'graphql-tag';

export const allBlockFields = gql`
  fragment AllBlockFields on BlockFields {
    title
    employer
    city
    startDate
    endDate
    current
    hideDates
    hideStartDate
    description
    skill
    skillLevel
    label
    url
    school
    degree
    hobbies
    language
    languageLevel
    course
    institution
    fullName
    company
    phone
    email
    drivingLicense
    publicationArticleTitle
    publicationTitle
    publicationDate
    dateOfBirth
    nationality
    visaStatus
    maritalStatus
  }
`;

export const fullDetails = gql`
  fragment FullDetails on ResumeDetails {
    sectionTitle
    title
    firstName
    lastName
    email
    phone
    userPic
    country
    countryCode
    city
    address
    postalCode
    isValidUserPicture
    professionalSummary
    socialLinks {
      label
      url
    }
  }
`;

export const userFull = gql`
  fragment UserFull on User {
    id
    createdAt
    role
    firstName
    lastName
    email
    emails {
      email
      verified
      primary
    }
    photo
    phone
    address
    city
    postalCode
    country
    onboard
    template
    customerId
    subscriptionId
    showDisclaimer
    initialDevice
    primaryLanguage
    billingVersion
    specialPlans
    pendingSubscription
    domain
    gender
    enableJobTrack
    freeAccessUntil
    isValidUserPicture
    extensionOnboardOption
    howQuicklyLookingForNewJob
    amountSpendForCareer
    isNeedOfResume
    professionHelpServices
    disableCarrerQuiz
    currentCareerStatus
    agentServiceEnabled
    contactExpert
  }
`;

export const slugFull = gql`
  fragment SlugFull on Slug {
    slug
    sourceId
    color
    template
    isPrivate
    updatedAt
    createdAt
  }
`;
