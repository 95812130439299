import React, { useContext, createContext, useReducer, useState } from 'react';
import { cloneDeep } from 'lodash';

const updateState = (state, action) => {
  const data = cloneDeep(state);
  data[action.name] = action.payload;
  return data;
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return updateState(state, action);
    default:
      return state;
  }
};

const GlobalContext = createContext();
export const Provider = GlobalContext.Provider;
export const BlockConsumer = GlobalContext.Consumer;

const initialState = {
  userData: null,
  userDisqualify: false,
  scoreAnalysis: false,
  experienceJobTitleData: [],
  allKeywords: [],
  searchabilityData: [],
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, { ...initialState });
  const [score, setScore] = useState(null);
  const updateScore = (newScore) => {
    setScore(newScore);
  };
  const handleUpdateState = (name, payload) => {
    dispatch({
      type: 'UPDATE_STATE',
      name,
      payload,
    });
  };
  return (
    <Provider
      value={{
        state: state,
        dispatch: dispatch,
        handleUpdateState,
        updateScore,
        score,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalState = () => useContext(GlobalContext);

export const withGlobalContext = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <GlobalContext.Consumer>
          {(context) => <WrappedComponent {...this.props} context={context} />}
        </GlobalContext.Consumer>
      );
    }
  };
};

export default GlobalStateProvider;
