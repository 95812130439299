import styled, { css } from 'styled-components';

import { buildResponsiveWidthMixin } from '/styles/mixins';
import { flexChildMixin, hiddenMixin } from '/imports/core/ui/helpers';

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['direction', 'alignItems', 'justifyContent', 'wrap', 'fullWidth', 'disabled', 'grid'].includes(prop),
})`
  display: flex;
  flex-direction: ${(props) => props.$direction || props.direction || 'row'};
  align-items: ${(props) => props.$alignItems || props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.$justifyContent || props.justifyContent || 'flex-start'};
  flex-wrap: ${(props) => props.$wrap || props.wrap || 'nowrap'};
  ${(p) =>
    (p.fullWidth || p.$fullWidth) &&
    css`
      width: 100%;
    `}
  ${(p) =>
    (p.grid || p.$grid) &&
    css`
      flex-wrap: wrap;
      margin-left: -${p.theme.general.gridGap};
      margin-right: -${p.theme.general.gridGap};
      > div {
        flex-grow: 0;
        flex-shrink: 0;
      }
    `}
  ${(p) =>
    (p.disabled || p.$disabled) &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    `}
  ${hiddenMixin}
  ${flexChildMixin}
  ${buildResponsiveWidthMixin}
`;

export default Flex;
