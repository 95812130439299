export const countryGroup = [
  {
    Country: 'Argentina',
    Code: 'AR',
    GoupId: '9',
  },
  {
    Country: 'Malaysia',
    Code: 'MY',
    GoupId: '9',
  },
  {
    Country: 'Peru',
    Code: 'PE',
    GoupId: '9',
  },
  {
    Country: 'United States',
    Code: 'US',
    GoupId: '9',
  },
  {
    Country: 'Colombia',
    Code: 'CO',
    GoupId: '9',
  },
  {
    Country: 'Philippines',
    Code: 'PH',
    GoupId: '9',
  },
  {
    Country: 'Brunei',
    Code: 'BN',
    GoupId: '9',
  },
  {
    Country: 'Moldova',
    Code: 'MD',
    GoupId: '9',
  },
  {
    Country: 'South Korea',
    Code: 'KR',
    GoupId: '8',
  },
  {
    Country: 'Japan',
    Code: 'JP',
    GoupId: '8',
  },
  {
    Country: 'Ecuador',
    Code: 'EC',
    GoupId: '7',
  },
  {
    Country: 'South Africa',
    Code: 'ZA',
    GoupId: '7',
  },
  {
    Country: 'Dominican Republic',
    Code: 'DO',
    GoupId: '7',
  },
  {
    Country: 'Brazil',
    Code: 'BR',
    GoupId: '7',
  },
  {
    Country: 'Kyrgyzstan',
    Code: 'KG',
    GoupId: '7',
  },
  {
    Country: 'Palestine',
    Code: 'PS',
    GoupId: '7',
  },
  {
    Country: 'Bosnia and Herzegovina',
    Code: 'BA',
    GoupId: '7',
  },
  {
    Country: 'Botswana',
    Code: 'BW',
    GoupId: '7',
  },
  {
    Country: 'Uzbekistan',
    Code: 'UZ',
    GoupId: '7',
  },
  {
    Country: 'Albania',
    Code: 'AL',
    GoupId: '7',
  },
  {
    Country: 'Tanzania',
    Code: 'TZ',
    GoupId: '7',
  },
  {
    Country: 'Tajikistan',
    Code: 'TJ',
    GoupId: '7',
  },
  {
    Country: 'Uruguay',
    Code: 'UY',
    GoupId: '7',
  },
  {
    Country: 'Armenia',
    Code: 'AM',
    GoupId: '7',
  },
  {
    Country: 'Kosovo',
    Code: 'XK',
    GoupId: '7',
  },
  {
    Country: 'Namibia',
    Code: 'NA',
    GoupId: '7',
  },
  {
    Country: 'Bolivia',
    Code: 'BO',
    GoupId: '6',
  },
  {
    Country: 'Paraguay',
    Code: 'PY',
    GoupId: '6',
  },
  {
    Country: 'Kenya',
    Code: 'KE',
    GoupId: '6',
  },
  {
    Country: 'Jordan',
    Code: 'JO',
    GoupId: '6',
  },
  {
    Country: 'Lebanon',
    Code: 'LB',
    GoupId: '6',
  },
  {
    Country: 'Ghana',
    Code: 'GH',
    GoupId: '6',
  },
  {
    Country: 'Nigeria',
    Code: 'NG',
    GoupId: '6',
  },
  {
    Country: 'Uganda',
    Code: 'UG',
    GoupId: '6',
  },
  {
    Country: 'Papua New Guinea',
    Code: 'PG',
    GoupId: '6',
  },
  {
    Country: 'Zimbabwe',
    Code: 'ZW',
    GoupId: '6',
  },
  {
    Country: 'Switzerland',
    Code: 'CH',
    GoupId: '5',
  },
  {
    Country: 'Latvia',
    Code: 'LV',
    GoupId: '5',
  },
  {
    Country: 'Norway',
    Code: 'NO',
    GoupId: '5',
  },
  {
    Country: 'Romania',
    Code: 'RO',
    GoupId: '5',
  },
  {
    Country: 'Sweden',
    Code: 'SE',
    GoupId: '5',
  },
  {
    Country: 'Israel',
    Code: 'IL',
    GoupId: '5',
  },
  {
    Country: 'Ireland',
    Code: 'IE',
    GoupId: '5',
  },
  {
    Country: 'Austria',
    Code: 'AT',
    GoupId: '5',
  },
  {
    Country: 'Estonia',
    Code: 'EE',
    GoupId: '5',
  },
  {
    Country: 'Luxembourg',
    Code: 'LU',
    GoupId: '5',
  },
  {
    Country: 'Iceland',
    Code: 'IS',
    GoupId: '5',
  },
  {
    Country: 'Guadeloupe',
    Code: 'GP',
    GoupId: '5',
  },
  {
    Country: 'Martinique',
    Code: 'MQ',
    GoupId: '5',
  },
  {
    Country: 'Bulgaria',
    Code: 'BG',
    GoupId: '5',
  },
  {
    Country: 'Mexico',
    Code: 'MX',
    GoupId: '5',
  },
  {
    Country: 'France',
    Code: 'FR',
    GoupId: '4',
  },
  {
    Country: 'Czechia',
    Code: 'CZ',
    GoupId: '4',
  },
  {
    Country: 'Spain',
    Code: 'ES',
    GoupId: '4',
  },
  {
    Country: 'Croatia',
    Code: 'HR',
    GoupId: '4',
  },
  {
    Country: 'Slovenia',
    Code: 'SI',
    GoupId: '4',
  },
  {
    Country: 'Singapore',
    Code: 'SG',
    GoupId: '4',
  },
  {
    Country: 'Hong Kong',
    Code: 'HK',
    GoupId: '4',
  },
  {
    Country: 'Netherlands',
    Code: 'NL',
    GoupId: '4',
  },
  {
    Country: 'Italy',
    Code: 'IT',
    GoupId: '4',
  },
  {
    Country: 'Slovakia',
    Code: 'SK',
    GoupId: '4',
  },
  {
    Country: 'Réunion',
    Code: 'RE',
    GoupId: '4',
  },
  {
    Country: 'French Guiana',
    Code: 'GF',
    GoupId: '4',
  },
  {
    Country: 'United Arab Emirates',
    Code: 'AE',
    GoupId: '3',
  },
  {
    Country: 'Saudi Arabia',
    Code: 'SA',
    GoupId: '3',
  },
  {
    Country: 'Serbia',
    Code: 'RS',
    GoupId: '3',
  },
  {
    Country: 'Hungary',
    Code: 'HU',
    GoupId: '3',
  },
  {
    Country: 'Qatar',
    Code: 'QA',
    GoupId: '3',
  },
  {
    Country: 'Georgia',
    Code: 'GE',
    GoupId: '3',
  },
  {
    Country: 'Oman',
    Code: 'OM',
    GoupId: '3',
  },
  {
    Country: 'North Macedonia',
    Code: 'MK',
    GoupId: '3',
  },
  {
    Country: 'Sri Lanka',
    Code: 'LK',
    GoupId: '3',
  },
  {
    Country: 'Turkey',
    Code: 'TR',
    GoupId: '3',
  },
  {
    Country: 'Azerbaijan',
    Code: 'AZ',
    GoupId: '3',
  },
  {
    Country: 'New Caledonia',
    Code: 'NC',
    GoupId: '3',
  },
  {
    Country: 'French Polynesia',
    Code: 'PF',
    GoupId: '3',
  },
  {
    Country: 'Bahrain',
    Code: 'BH',
    GoupId: '3',
  },
  {
    Country: 'New Zealand',
    Code: 'NZ',
    GoupId: '3',
  },
  {
    Country: 'Indonesia',
    Code: 'ID',
    GoupId: '2',
  },
  {
    Country: 'India',
    Code: 'IN',
    GoupId: '2',
  },
  {
    Country: 'Pakistan',
    Code: 'PK',
    GoupId: '2',
  },
  {
    Country: 'Venezuela',
    Code: 'VE',
    GoupId: '2',
  },
  {
    Country: 'Morocco',
    Code: 'MA',
    GoupId: '2',
  },
  {
    Country: 'Egypt',
    Code: 'EG',
    GoupId: '2',
  },
  {
    Country: 'Iraq',
    Code: 'IQ',
    GoupId: '2',
  },
  {
    Country: 'China',
    Code: 'CN',
    GoupId: '2',
  },
  {
    Country: 'Nepal',
    Code: 'NP',
    GoupId: '2',
  },
  {
    Country: 'Bangladesh',
    Code: 'BD',
    GoupId: '2',
  },
  {
    Country: 'Ethiopia',
    Code: 'ET',
    GoupId: '2',
  },
  {
    Country: 'Tunisia',
    Code: 'TN',
    GoupId: '2',
  },
  {
    Country: 'Senegal',
    Code: 'SN',
    GoupId: '2',
  },
  {
    Country: 'Myanmar',
    Code: 'MM',
    GoupId: '2',
  },
  {
    Country: 'Cameroon',
    Code: 'CM',
    GoupId: '2',
  },
  {
    Country: 'Russia',
    Code: 'RU',
    GoupId: '2',
  },
  {
    Country: 'Kazakhstan',
    Code: 'KZ',
    GoupId: '1',
  },
  {
    Country: 'Chile',
    Code: 'CL',
    GoupId: '1',
  },
  {
    Country: 'Lithuania',
    Code: 'LT',
    GoupId: '1',
  },
  {
    Country: 'Germany',
    Code: 'DE',
    GoupId: '1',
  },
  {
    Country: 'Greece',
    Code: 'GR',
    GoupId: '1',
  },
  {
    Country: 'United Kingdom',
    Code: 'GB',
    GoupId: '1',
  },
  {
    Country: 'Belgium',
    Code: 'BE',
    GoupId: '1',
  },
  {
    Country: 'Finland',
    Code: 'FI',
    GoupId: '1',
  },
  {
    Country: 'Poland',
    Code: 'PL',
    GoupId: '1',
  },
  {
    Country: 'Canada',
    Code: 'CA',
    GoupId: '1',
  },
  {
    Country: 'Taiwan',
    Code: 'TW',
    GoupId: '1',
  },
  {
    Country: 'Portugal',
    Code: 'PT',
    GoupId: '1',
  },
  {
    Country: 'Jamaica',
    Code: 'JM',
    GoupId: '1',
  },
  {
    Country: 'Australia',
    Code: 'AU',
    GoupId: '1',
  },
  {
    Country: 'Ukraine',
    Code: 'UA',
    GoupId: '1',
  },
  {
    Country: 'Denmark',
    Code: 'DK',
    GoupId: '1',
  },
  {
    Country: 'Macao',
    Code: 'MO',
    GoupId: '1',
  },
  {
    Country: 'Montenegro',
    Code: 'ME',
    GoupId: '1',
  },
  {
    Country: 'Thailand',
    Code: 'TH',
    GoupId: '1',
  },
  {
    Country: 'Vietnam',
    Code: 'VN',
    GoupId: '0',
  },
  {
    Country: 'Algeria',
    Code: 'DZ',
    GoupId: '0',
  },
];
