const LayoutSearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#dnilf06vba)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 0a6.5 6.5 0 0 1 5.248 10.336l3.545 3.546a1 1 0 0 1-1.414 1.414l-3.546-3.546A6.5 6.5 0 1 1 6.5 0zm0 2a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9z"
          fill="#4C4C55"
        />
      </g>
      <defs>
        <clipPath id="dnilf06vba">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LayoutSearchIcon;
