import React, { useContext } from 'react';

const AccountContext = React.createContext();

AccountContext.displayName = 'AccountContext';

export const AccountProvider = AccountContext.Provider;
export const AccountConsumer = AccountContext.Consumer;

export const withAccount = (Component) =>
  class Wrapper extends React.Component {
    static async getInitialProps(ctx) {
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      return { ...pageProps };
    }

    render() {
      return (
        <AccountConsumer>
          {(currentUser) => {
            return <Component {...this.props} {...currentUser} />;
          }}
        </AccountConsumer>
      );
    }
  };

export const useAccount = () => useContext(AccountContext);
export default AccountContext;
