import styled, { css } from 'styled-components';

export const Text = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  white-space: normal !important;
  word-break: break-word !important;
  break-inside: auto;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default Text;
