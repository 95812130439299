import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const MixpanelContext = createContext();

export function MixpanelProvider({ children }) {
  const mixpanel = useMixpanelProvider();
  return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
}

function useMixpanelProvider() {
  const analytics = typeof window === 'object' && window.analytics ? window.analytics : {};

  const initialized = () => analytics.config?.token;

  const poll = (type) => (firstParam, secondParam) => {
    return;
    setTimeout(() => {
      if (!initialized()) return;
      switch (type) {
        case 'reset':
          return analytics.reset();
        case 'identify':
          return analytics.identify();
        case 'set':
          return analytics.people.set(firstParam);
        case 'alias':
          return analytics.alias(firstParam);
        case 'track':
        default:
          return analytics.track(firstParam, secondParam);
      }
    }, 500);
  };

  const track = (eventName, properties) => {
    return;
    try {
      if (analytics.track) return analytics.track(eventName, properties);
      poll('track')(eventName, properties);
    } catch (e) {
      console.log('error', e);
    }
  };

  const reset = () => {
    return;
    try {
      if (analytics.reset) return analytics.reset();
      poll('reset')();
    } catch (e) {
      console.log('error', e);
    }
  };

  const identify = () => {
    return;
    try {
      if (analytics.identify) return analytics.identify();
      poll('identify')();
    } catch (e) {
      console.log('error', e);
    }
  };

  const peopleSet = (properties) => {
    return;
    try {
      if (analytics.people?.set) return analytics.people.set(properties);
      poll('set')(properties);
    } catch (e) {
      console.log('error', e);
    }
  };

  const alias = (id) => {
    return;
    try {
      if (analytics.alias) return analytics.alias(id);
      poll('alias')(id);
    } catch (e) {
      console.log('error', e);
    }
  };

  return {
    track,
    reset,
    identify,
    alias,
    people: {
      set: peopleSet,
    },
  };
}

const useMixpanel = () => useContext(MixpanelContext);

export { useMixpanel };

MixpanelProvider.propTypes = {
  children: PropTypes.node,
};
