import { theme as designTheme } from '/styles/theme';
import { min, max, between, getSizeFromBreakpoint } from '/styles/mixins';

export const getStripeInputStyleProps = () => ({
  style: {
    base: {
      fontSize: '16px',
      color: designTheme.colors.black,
      fontWeight: 200,
      lineHeight: 1.7,
      fontFamily: designTheme.font.family.text,
      '::placeholder': {
        color: designTheme.colors.gray.regular,
        fontWeight: 200,
      },
    },
    invalid: {
      color: designTheme.colors.black,
    },
  },
});

export const getCharacterLength = (str, left) => {
  if (left) return (str.match(/\[/g) || []).length;
  return (str.match(/]/g) || []).length;
};

export const theme = (path, displayError) => (props) =>
  path.split('.').reduce((res, segment) => {
    if (!res[segment]) return displayError ? console.error(`No prop ${segment} on current theme`) : null;
    return res[segment];
  }, props.theme);

const getColorRule = (color) => `color: ${color};`;

export const colorMixin = (props) => {
  if (!props.color) return '';
  const themeColor = theme(
    `colors.${props.color}`,
    false,
  )({
    theme: designTheme,
  });
  if (themeColor) return getColorRule(themeColor);
  return getColorRule(props.color);
};

export const flexChildMixin = (props) => {
  let rules = '';
  if (props.order) rules += `order: ${props.order};`;
  if (props.grow) rules += `flex-grow: ${props.grow};`;
  if (props.shrink) rules += `flex-shrink: ${props.shrink};`;
  return rules;
};

export const hiddenMixin = (props) => {
  let res = '';
  if (props.hiddenXS) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('xs')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenSM) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('sm')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenMD) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('md')}) {
        display: none !important;
      }
    `;
  }
  if (props.hiddenLG) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('lg')}) {
        display: none !important;
      }
    `;
  }
  return res;
};

export const responsiveRule = (ruleName, propPath) => {
  const values = theme(propPath)({ theme: designTheme });
  let res = '';
  if (!values) return res;
  if (values.lg) {
    res += `
      @media (min-width: ${getSizeFromBreakpoint('lg')}) {
        ${ruleName}: ${values.lg};
      }
    `;
  }
  if (values.md) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('lg')}) {
        ${ruleName}: ${values.md};
      }
    `;
  }
  if (values.sm) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('md')}) {
        ${ruleName}: ${values.sm};
      }
    `;
  }
  if (values.xs) {
    res += `
      @media (max-width: ${getSizeFromBreakpoint('sm')}) {
        ${ruleName}: ${values.xs};
      }
    `;
  }
  return res;
};

export const media = { min, max, between };

export const generateDebounceKey = (variables) => {
  const { path, field } = variables;
  if (!path && !field) return 'autosave';
  if (field) return `autosave-${field}`;
  if (path.includes('.')) {
    return `autosave-${path.substring(path.lastIndexOf('.') + 1)}`;
  } else {
    return `autosave-${path}`;
  }
};

export const getEmailProvider = (email) => {
  if (!email) return;

  const atIndex = email.indexOf('@');
  const dotIndex = email.indexOf('.');

  return email.slice(atIndex, dotIndex);
};

export const replaceString = (str, words) => {
  let parameters = {};
  Object.keys(words).forEach((item) => {
    parameters[item.toLowerCase()] = words[item];
  });
  const regex = new RegExp(Object.keys(words).join('|'), 'gi');
  return str.replace(regex, function (matched) {
    return parameters[matched.toLowerCase()];
  });
};

export const isFormInput = (element) => {
  var nodeName = element?.nodeName?.toLowerCase();

  if (
    element?.nodeType == 1 &&
    (nodeName == 'textarea' ||
      (nodeName == 'input' && /^(?:text|email|number|search|tel|url|password)$/i.test(element?.type)))
  )
    return true;
  return false;
};

export const isInput = (element) => {
  return isFormInput(element) || element?.isContentEditable;
};

export const getFirstInput = (element) => {
  if (isInput(element)) return element;

  const children = Array.from(element?.children || []);

  for (const child of children) {
    const foundElement = getFirstInput(child);
    if (foundElement) return foundElement;
  }

  return null;
};

export const clickOn = (editButtonElement) => {
  if (editButtonElement) {
    editButtonElement.click();
    editButtonElement.scrollIntoView({ block: 'center' });
  }
};

export const focusAndSelect = (element) => {
  const inputElement = getFirstInput(element);

  if (inputElement) {
    inputElement.focus();
    handleSelect(inputElement);
    inputElement.scrollIntoView({ block: 'center' });
  }
};

//allows deselecting the selected element
export const handleDeselect = () => {
  const selection = window.getSelection();
  selection.removeAllRanges();
  return selection;
};

//alllow managing the selection for input elements as well as contenteditable elements
export const handleSelect = (element) => {
  if (isInput(element)) {
    if (element.isContentEditable) {
      const range = document.createRange();
      const selection = handleDeselect();

      range.selectNodeContents(element);

      selection.addRange(range);
    } else {
      element.select();
    }
  }
};
