import styled, { css } from 'styled-components';

export const Document = styled.section`
  position: relative;
  ${(p) =>
    p.printHeight &&
    css`
      min-height: ${p.printHeight}px;
      overflow-y: hidden;
    `}
  opacity: 1;
`;
export default Document;
