import ct from 'countries-and-timezones';
import moment from 'moment-timezone';

export const getStripeSCAEnabled = () => {
  return true;
};

export const getTimezone = () => {
  return moment.tz.guess(true);
};

export const getCountry = () => {
  const timeZone = moment.tz.guess(true);
  const { countries = [''] } = ct.getTimezone(timeZone) || {};
  return countries?.[0];
};

export const getCountryName = () => {
  const timeZone = moment.tz.guess(true);
  const tz = ct.getTimezone(timeZone);
  const { name: country } = ct.getCountry(tz.countries?.[0]);
  return country;
};

/**
 * Formats a number to currency parts.
 */
export const helperCurrencyFormatter = (price, currency, locale) => {
  const splitCurrency = parseFloat(price, 10).toFixed(2).toString().split('.');
  const integer = splitCurrency[0];
  const fraction = splitCurrency[1];
  switch (currency) {
    case 'CHF':
      return [
        { type: 'currency-prefix', value: 'CHF' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'ILS':
      return [
        { type: 'currency-prefix', value: '₪' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'THB':
      return [
        { type: 'currency-prefix', value: 'THB' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'BRL':
      return [
        { type: 'currency-prefix', value: 'R' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'RUB':
      return [
        { type: 'currency-prefix', value: 'RUB' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'CZK':
      return [
        { type: 'currency-prefix', value: 'CZK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'NOK':
      return [
        { type: 'currency-prefix', value: 'NOK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SAR':
      return [
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
        { type: 'currency-postfix', value: 'SAR' },
      ];
    case 'AED':
      return [
        { type: 'currency-prefix', value: 'AED' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'PHP':
      return [
        { type: 'currency-prefix', value: 'PHP' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'HUF':
      return [
        { type: 'currency-prefix', value: 'HUF' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'TRY':
      return [
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
        { type: 'currency-postfix', value: 'TL' },
      ];
    case 'HKD':
      return [
        { type: 'currency-prefix', value: 'HK' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'ARS':
      return [
        { type: 'currency-prefix', value: 'ARS' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'DKK':
      return [
        { type: 'currency-prefix', value: 'DKK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'PLN':
      return [
        { type: 'currency-prefix', value: 'PLN' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'MXN':
      return [
        { type: 'currency-prefix', value: 'MX' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SEK':
      return [
        { type: 'currency-prefix', value: 'SEK' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'SGD':
      return [
        { type: 'currency-prefix', value: 'S' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'CAD':
      return [
        { type: 'currency-prefix', value: 'C' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'NZD':
      return [
        { type: 'currency-prefix', value: 'NZ' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'EUR':
      return locale === 'fr'
        ? [
            { type: 'integer', value: integer },
            { type: 'decimal', value: ',' },
            { type: 'fraction', value: fraction },
            { type: 'currency', value: '€' },
          ]
        : [
            { type: 'currency', value: '€' },
            { type: 'integer', value: integer },
            { type: 'decimal', value: ',' },
            { type: 'fraction', value: fraction },
          ];
    case 'GBP':
      return [
        { type: 'currency', value: '£' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'AUD':
      return [
        { type: 'currency-prefix', value: 'A' },
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'INR':
      return [
        { type: 'currency', value: '₹' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'EGP':
      return [
        { type: 'currency', value: 'LE' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'ZAR':
      return [
        { type: 'currency', value: 'R' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
    case 'USD':
    default:
      return [
        { type: 'currency', value: '$' },
        { type: 'integer', value: integer },
        { type: 'decimal', value: '.' },
        { type: 'fraction', value: fraction },
      ];
  }
};

/**
 * Formats a number to currency with Intl.
 */
export const formatCurrency = (amount, currency, locale) => {
  const formatter = helperCurrencyFormatter(amount.toFixed(2), currency, locale);
  const values = formatter.map((t) => t.value);
  return values.join('');
};
