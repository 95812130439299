const BoardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04 1.597A2.01 2.01 0 0 0 1 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H3a2 2 0 0 0-1.96 1.597zM3 2h10v5H3V2zm0 7v5h10V9H3zm3-4h4V4H6v1zm4 7H6v-1h4v1z"
        fill="#4C4C55"
      />
    </svg>
  );
};

export default BoardIcon;
