import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ToasterContext = createContext();

export function ToasterProvider({ children }) {
  const provider = useToasterProvider();
  return <ToasterContext.Provider value={provider}>{children}</ToasterContext.Provider>;
}

function useToasterProvider() {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({ text: '' });
  let timeoutId = null;
  function toast(toastInfo) {
    const timeoutSecond = toastInfo.error ? 5000 : 2000;
    setOpen(true);
    setInfo(toastInfo);
    timeoutId = setTimeout(() => {
      setOpen(false);
    }, timeoutSecond);
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);

  return {
    toast,
    open,
    info,
    setOpen,
  };
}
const toastHook = () => useContext(ToasterContext);
export default toastHook;
ToasterProvider.propTypes = {
  children: PropTypes.node,
};
