const WishlistHeartIcon = ({ isColored }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
      <path
        d="M13.3772 2.42238C12.014 1.0592 9.80353 1.0592 8.44035 2.42238C8.27225 2.59048 8.12633 2.77181 7.99961 2.9621C7.87289 2.77181 7.72697 2.59048 7.55887 2.4228C6.19569 1.05963 3.98517 1.05963 2.62199 2.4228C1.25882 3.78598 1.25882 5.9965 2.62199 7.35968L7.99961 12.7369L13.3772 7.35925C14.7404 5.99608 14.7404 3.78598 13.3772 2.42238Z"
        stroke={isColored ? 'black' : 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default WishlistHeartIcon;
