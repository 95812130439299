import getConfig from 'next/config';

const {
  publicRuntimeConfig: {
    PORT,
    NODE_ENV,
    DOMAIN,
    BASE_API,
    JOB_TRACK_API,
    GC_API_KEY,
    COVER_LETTERS_ENDPOINT,
    BILLING_ENDPOINT,
    DOCX_ENDPOINT,
    DISABLE_PLACES_API,
    MIXPANEL_KEY,
    GENDER_API_KEY,
    BLOG_ENDPOINT,
    TOKENEX_API_KEY,
    TOKENEX_ID,
    GOOGLE_ANALYTICS_ID,
    SC_ATTR,
    PADDLE_VENDOR_ID,
    BASE_API_GRAPHQL,
    AIRTABLE_API_KEY,
    SENDINBLUE_CLIENT_ID,
    INDICATIVE_KEY,
    ONESIGNAL_APP_ID,
    ONESIGNAL_SAFARI_WEB_ID,
    OPENAI_API_KEY,
    AIRTABLE_TOKEN,
    ROLLBAR_CLIENT_TOKEN,
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    PLACEKIT_API_KEY,
  },
} = getConfig();

const config = {
  PORT,
  NODE_ENV,
  DOMAIN,
  BASE_API,
  JOB_TRACK_API,
  MIXPANEL_KEY,
  GC_API_KEY,
  COVER_LETTERS_ENDPOINT,
  BILLING_ENDPOINT,
  DOCX_ENDPOINT,
  DISABLE_PLACES_API,
  GENDER_API_KEY,
  BLOG_ENDPOINT,
  TOKENEX_API_KEY,
  TOKENEX_ID,
  GOOGLE_ANALYTICS_ID,
  SC_ATTR,
  PADDLE_VENDOR_ID,
  BASE_API_GRAPHQL,
  AIRTABLE_API_KEY,
  SENDINBLUE_CLIENT_ID,
  INDICATIVE_KEY,
  ONESIGNAL_APP_ID,
  ONESIGNAL_SAFARI_WEB_ID,
  OPENAI_API_KEY,
  AIRTABLE_TOKEN,
  ROLLBAR_CLIENT_TOKEN,
  PLACEKIT_API_KEY,
};

export default config;
