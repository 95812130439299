import { COVER_LETTER_SOURCE, SPLIT_SKILLS_BLOCK, TEMPLATES_FONTS_ARABIK } from './constants';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash';
import qs from 'qs';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import sortBy from 'lodash/sortBy';
import sortedUniq from 'lodash/sortedUniq';
import { ContentState, convertToRaw } from 'draft-js';

import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { BLOCK_KEYS, BLOCK_KEYS_SINGULAR, EXTRA_CURRICULAR_DEFAULT, TEMPLATES } from '/imports/generator/api/constants';
import { displayDate, getBlockTranslationType, getCustomTitle } from '/imports/generator/api/helpers';
import { getDefaultLanguage, getExpSkillsTagVars } from '/lib/helpers';
import { Link, OrderedListItem, UnbreakableView, UnorderedListItem, View } from '/imports/pdf/core/ui/atoms';
import { TEMPLATES_FONTS, WIDE_FONTS } from '/imports/pdf/core/api/constants';
import { Push } from '/components/Link';

const blockEmpty = (block) => !block || !block.items || !block.items.length;

export const isDescriptionEmpty = (description) => {
  try {
    if (!description) return true;
    const { blocks } = JSON.parse(description);
    return blocks && blocks.length === 1 && blocks[0].text === '';
  } catch (error) {
    return false;
  }
};

export const getResumeWithPlaceholders = (resume) => {
  if (!resume) return null;
  //disactivate placeholders
  return resume;
};

export const optionalPortal = (styles, element, isDragDisabled) => {
  if (isDragDisabled) return element;
  const _dragEl = typeof document !== 'undefined' && document.getElementById('draggable');
  if (styles.position === 'fixed') {
    return createPortal(element, _dragEl);
  }
  return element;
};

export const handleMouseEnter = () => {
  const node = document.getElementById('btn-enlarge');
  if (node) node.style.display = 'none';
};

export const handleMouseLeave = () => {
  const node = document.getElementById('btn-enlarge');
  if (node) node.style.display = 'flex';
};
export const handleBlockClick = (e) => {
  const currentPath = (typeof window !== 'undefined' && window?.location.pathname) || '';
  if (currentPath.includes('/resumes') || currentPath.includes('/dashboard')) return;
  if (e) e.stopPropagation();
};

const inRange = ({ st, ed }, { offset, length }, text) => {
  const _ed = ed >= text.length ? text.length - 1 : ed;
  return st >= offset && _ed <= offset + length - 1;
};

const applyStyle = (style, type) => {
  let addStyle = {};
  switch (type) {
    case 'BOLD':
      addStyle.bold = true;
      break;
    case 'ITALIC':
      addStyle.italic = true;
      break;
    case 'UNDERLINE':
      addStyle.underline = true;
      break;
    case 'LINETHROUGH':
      addStyle.linethrough = true;
      break;
  }
  return Object.assign(style, addStyle);
};

const renderBlock = (
  { inlineStyleRanges, entityRanges, text, key, type },
  entityMap,
  { num, font, BaseText, center, length, weight, isRTL },
  first,
  atsresume,
) => {
  let breakpoints = [];
  let intervals = [];
  inlineStyleRanges.forEach((isr) => {
    breakpoints.push(isr.offset);
    const bpCandidate = isr.offset + isr.length;
    if (bpCandidate < text.length) breakpoints.push(bpCandidate);
  });
  entityRanges.forEach((er) => {
    breakpoints.push(er.offset);
    const bpCandidate = er.offset + er.length;
    if (bpCandidate < text.length) breakpoints.push(bpCandidate);
  });
  breakpoints = sortedUniq(sortBy(breakpoints));

  let pointer = 0;
  breakpoints.forEach((bp) => {
    if (bp !== 0) {
      intervals.push({
        st: pointer,
        ed: bp - 1,
      });
    }
    pointer = bp;
  });
  if (pointer < text.length) {
    intervals.push({
      st: pointer,
      ed: text.length,
    });
  }
  const data = intervals.map((interval) => {
    const textPart = text.substring(interval.st, interval.ed + 1);
    let style = {};
    let data = {};
    let type = 'text';

    inlineStyleRanges.forEach((isr) => {
      if (inRange(interval, isr, text)) {
        style = applyStyle(style, isr.style);
      }
    });
    entityRanges.forEach((er) => {
      if (inRange(interval, er, text)) {
        const { key } = er;
        const entity = entityMap[key];
        if (entity && entity.type === 'LINK') {
          type = 'link';
          data = Object.assign(data, entity.data);
        }
      }
    });
    return { text: textPart, type, style, data };
  });

  const content = data.map((part, index) => renderPart(part, { font, BaseText }, index));
  let result = <BaseText className="draft-text-content">{content.length > 0 ? content : <br />}</BaseText>;
  if (type === 'unordered-list-item') {
    result = (
      <UnorderedListItem
        BaseText={BaseText}
        center={center}
        weight={weight}
        isRTL={isRTL}
        first={first}
        atsResume={atsresume}
      >
        <BaseText>{content.length > 0 ? content : <br />}</BaseText>
      </UnorderedListItem>
    );
  }
  if (type === 'ordered-list-item') {
    result = (
      <OrderedListItem
        bigPaddings={WIDE_FONTS.includes(font)}
        num={num}
        length={length}
        BaseText={BaseText}
        center={center}
        first={first}
        className={'ordered-list-item'}
      >
        <BaseText>{content.length > 0 ? content : <br />}</BaseText>
      </OrderedListItem>
    );
  }
  if (type === 'left-align' || type === 'right-align' || type === 'center-align' || type === 'justify-align') {
    let value = 'left';
    if (type === 'right-align') value = 'right';
    if (type === 'center-align') value = 'center';
    if (type === 'justify-align') value = 'justify';
    result = (
      <StyledWrapper align={value}>
        <BaseText>{content}</BaseText>
      </StyledWrapper>
    );
  }
  return (
    <UnbreakableView isBreakable key={key} $atsresume={atsresume}>
      {result}
    </UnbreakableView>
  );
};

const renderPart = (
  { text, type, style: { bold, italic, underline, linethrough }, data: { url } },
  { font, BaseText },
  index,
  atsresume,
) => {
  const BaseComponent = type === 'link' ? Link : BaseText;
  const boldText = TEMPLATES_FONTS[font]?.bold || TEMPLATES_FONTS_ARABIK[font]?.bold || 'initial';
  const italicText = TEMPLATES_FONTS[font]?.italic || TEMPLATES_FONTS_ARABIK[font]?.italic || 'initial';
  const boldItalic = TEMPLATES_FONTS[font]?.boldItalic || TEMPLATES_FONTS_ARABIK[font]?.boldItalic || 'initial';
  const style = {
    textDecoration: underline || type === 'link' ? 'underline' : 'none',
    color: 'inherit',
    display: 'inline',
  };

  if (bold) {
    style.fontFamily = boldText;
    style.fontWeight = 'bold';
  }

  if (italic) {
    style.fontFamily = italicText;
    style.fontStyle = 'italic';
  }

  if (bold && italic) style.fontFamily = boldItalic;

  return (
    <BaseComponent style={style} href={url} key={index} target={type === 'link' ? '_blank' : null}>
      {linethrough ? <s>{text}</s> : text}
    </BaseComponent>
  );
};

export const parseDraftText = (text, BaseText, font, params, weight, atsresume) => {
  if (!text) return null;
  try {
    const { blocks, entityMap } = JSON.parse(text);
    const center = params ? params.center : false;
    const isPlaceholder = params && params.isPlaceholder;
    const isRTL = params && params.isRTL;
    let nextNum = 0;
    return (
      <ParsedTextContainer isPlaceholder={isPlaceholder} $atsResume={atsresume}>
        {blocks &&
          blocks.map((block, index) => {
            nextNum = block.type === 'ordered-list-item' ? nextNum + 1 : 0;
            return renderBlock(
              block,
              entityMap,
              {
                num: nextNum,
                length: blocks.length,
                font,
                BaseText,
                center,
                weight,
                isRTL,
              },
              index === 0,
              atsresume,
            );
          })}
      </ParsedTextContainer>
    );
  } catch (e) {
    return parseDraftText(
      JSON.stringify(convertToRaw(ContentState.createFromText(text))),
      BaseText,
      font,
      params,
      weight,
      atsresume,
    );
  }
};

const ParsedTextContainer = styled.div`
  ${(p) =>
    p.isPlaceholder &&
    css`
      opacity: 0.15;
    `}
  ${({ $atsResume, theme: { convertPx } }) =>
    $atsResume &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `}
`;

const StyledWrapper = styled(View)`
  text-align: ${({ align }) => align};
`;

export const displayLanguageLevel = (level) => {
  if (!level) return null;
  return (level.charAt(0) + level.slice(1).toLowerCase()).replace('_', ' ');
};

export const getBlockTitle = (
  t,
  blockName,
  isCustom,
  language,
  type,
  title,
  isRenameSectionTitle,
  defaultLanguage,
  locale,
) => {
  const renameSectionTitleVariant = marvelEmitter.getActiveVariant('exp_rename_section_title');
  const resumeLanguage = language || defaultLanguage;
  const noLocalUpperCaseLanguages = ['ka'];
  const isRenameSectionTitleActive =
    isRenameSectionTitle || typeof renameSectionTitleVariant == 'undefined' || isCustom;
  let blockNamed = blockName;

  switch (type) {
    case 'SOFT_SKILLS':
      blockNamed = blockNamed === 'Soft Skills' ? 'soft_skills' : blockNamed;
    case 'HARD_SKILLS':
      blockNamed = blockNamed === 'Hard Skills' ? 'hard_skills' : blockNamed;
    case 'CUSTOM_SKILLS_CATEGORY':
      blockNamed = blockNamed === 'Untitled' ? 'untitled' : blockNamed;
  }

  const customTitled = getCustomTitle(type, title, t(blockNamed, resumeLanguage), isRenameSectionTitleActive);

  if ((isCustom || isRenameSectionTitle) && title?.toLowerCase() === 'untitled')
    return t('untitled', resumeLanguage)?.toLocaleUpperCase(resumeLanguage);
  return noLocalUpperCaseLanguages.includes(resumeLanguage)
    ? customTitled
    : customTitled?.toLocaleUpperCase(resumeLanguage);
};

export const getBlockHideLevel = (resume, type) => {
  if (!resume) return null;
  const { blocks } = resume;
  const foundBlock = blocks.find((block) => block.type === type);
  const { hideLevel } = foundBlock;
  return hideLevel;
};

export const getSkillsTag = (resume, type) => {
  if (!resume) return null;
  const { blocks } = resume;
  const foundBlock = blocks.find((block) => block.type === type);
  if (!foundBlock) return;
  const { showTagUI } = foundBlock;
  return showTagUI;
};

export const getCustomBlockHideLevel = (resume, blockId) => {
  if (!resume) return null;
  const { blocks } = resume;
  const foundBlock = blocks.find((block) => block.id === blockId);
  const { hideLevel } = foundBlock;
  return hideLevel;
};

export const isCoverLetter = (source) => source === COVER_LETTER_SOURCE;

export const displayCoverLetterDate = (date, language, t) => {
  const format = language === 'en' ? 'MMMM D, YYYY' : 'D MMMM YYYY';
  return displayDate(date, format, language, t);
};

export const getBlockName = (block) => {
  switch (block.type) {
    case 'CUSTOM':
    case 'SOFT_SKILLS':
    case 'HARD_SKILLS':
    case 'CUSTOM_SKILLS_CATEGORY':
      return block.title;
    case 'INTERNSHIPS':
    case 'COURSES':
    case 'LANGUAGES':
    case 'REFERENCES':
    case 'EDUCATION':
    case 'SOCIAL_LINKS':
    case 'SKILLS':
      return block.items?.length <= 1 ? BLOCK_KEYS_SINGULAR[block.type] : BLOCK_KEYS[block.type];
    default:
      return BLOCK_KEYS[block.type];
  }
};

export const canDisplay = {
  DRIVING_LICENSE: (driving) => {
    return driving.items?.some((item) => item.fields && item.fields.drivingLicense);
  },
  PERSONAL_DETAILS: (personal) => {
    const fieldsList = ['dateOfBirth', 'nationality', 'visaStatus', 'maritalStatus'];
    return personal.items?.some((item) => item.fields && fieldsList.filter((i) => !!item.fields[i]).length);
  },
  PUBLICATIONS: (publications) => {
    return publications.items?.some((item) => item.fields && item.fields.publicationArticleTitle);
  },
  EDUCATION: (education) => {
    return education.items?.some((item) => item.fields);
  },
  SKILLS: (skills) => {
    return skills.items?.some((item) => item.fields && item.fields.skill?.trim());
  },
  SOFT_SKILLS: (skills) => {
    return skills.items?.some((item) => item.fields && item.fields.skill?.trim());
  },
  HARD_SKILLS: (skills) => {
    return skills.items?.some((item) => item.fields && item.fields.skill?.trim());
  },
  CUSTOM_SKILLS_CATEGORY: (skills) => {
    return skills.items?.some((item) => item.fields && item.fields.skill?.trim());
  },
  LANGUAGES: (languages) => {
    return languages.items?.some((item) => item.fields && item.fields.language);
  },
  HOBBIES: (hobbies) => {
    return hobbies.items?.some((item) => item.fields && !isEmpty(item.fields.description));
  },
  SOCIAL_LINKS: (links) => {
    return links.items?.some((item) => item.fields && (item.fields.url || item.fields.label));
  },
  REFERENCES: (references) => {
    return references.items?.some(
      (item) => item.fields && (item.fields.fullName || item.fields.company || item.fields.phone || item.fields.email),
    );
  },
  COURSES: (course) => {
    return course.items?.some((item) => item.fields && (item.fields.course || item.fields.institution));
  },
};

export const positionedBlocks = ({ blocks, verifyFixedBlockCount = true, verifySortableBlockCount = true }) => {
  const filterBlocks = (block, verifyCount) =>
    (block.type === 'REFERENCES' && block.hideReferences) ||
    !verifyCount ||
    (verifyCount && block.items && block.items.length);

  const fixedBlocks = blocks.filter((block) => filterBlocks(block, verifyFixedBlockCount) && block.fixedPosition);

  const sortableBlocks = sortBy(
    blocks.filter((block) => filterBlocks(block, verifySortableBlockCount) && !block.fixedPosition),
    'position',
  );

  return { fixedBlocks, sortableBlocks };
};

/**
 *
 * @param {Object} block
 * @returns {Boolean}
 * * only in the case where block.type is not included in
 * * Object.keys([...canDisplayBlock, ...['PROFESSIONAL_SUMMARY']]) array
 * * return true
 * * otherwise apply the conditions for displaying the blocks
 */
export const shouldRenderBlock = (block) => {
  const isReferenceBlock = block.type === 'REFERENCES';
  const isSummary = block.type === 'PROFESSIONAL_SUMMARY';
  const canDisplayBlock = Object.keys(canDisplay);

  if (![...canDisplayBlock, ...['PROFESSIONAL_SUMMARY']].includes(block.type)) return true;

  return (
    (canDisplay[block.type] &&
      ((isReferenceBlock && (block.hideReferences || canDisplay[block.type](block))) ||
        (!isReferenceBlock && canDisplay[block.type](block)))) ||
    (isSummary && !summaryEmpty(block))
  );
};

export const summaryEmpty = ({ type, items }) => {
  if (!items) return true;
  const isSummary = type === 'PROFESSIONAL_SUMMARY';
  return (
    (isSummary && !(items?.length && items[0].fields)) || (isSummary && isDescriptionEmpty(items[0].fields.description))
  );
};

export const getCorrectTemplateColor = (savedColor, template) => {
  try {
    const { colors } = TEMPLATES.find((t) => t.id === template);
    const color = colors.includes(savedColor) ? savedColor : colors[0];
    return color;
  } catch {
    return savedColor;
  }
};

export const getPersonalDetail = (value, language = 'en', isFormatted = false) => {
  const formattedDate = isValidDate(value)
    ? displayDate(value, isFormatted ? 'DD/MM/YYYY' : 'DD MMM YYYY', language)
    : value;
  return <>{formattedDate}</>;
};

export const renderDate = (date, showOnlyYear, language, t, withNonBreakingSpace = false) => {
  let dateStr = (date && displayDate(date, showOnlyYear ? 'YYYY' : 'MMM YYYY', language, t)) || '';
  if (withNonBreakingSpace) {
    dateStr = dateStr?.replace(' ', '\u00A0');
  }
  return dateStr;
};

/**
 * Check if the given date string is a valid date.
 *
 * @param {string} dateString - The date string to be checked.
 * @returns {boolean} `true` if the date is valid, `false` otherwise.
 */
export function isValidDate(dateString) {
  return !isNaN(Date.parse(dateString));
}

export const findBlockByItemId = (blocks, itemId) => {
  for (const block of blocks) {
    const itemFound = block?.items?.find((item) => item.id === itemId) || null;
    if (itemFound) return block;
  }
  return null;
};

/**
 * Generates properties for a "click to edit" functionality.
 *
 * @param {string} itemId - The ID of the item to edit.
 * @param {object} [targetsObj={}] - An object containing target fields.
 * @param {object} targetNameMap - A map of target field names.
 * @returns {object} An object containing the item ID and the target field.
 */
export const getClickToEditProps = (itemId, targetsObj = {}, targetNameMap) => {
  let obj = {
    blockItemId: itemId,
  };

  const newTargetMap = !targetNameMap
    ? targetsObj
    : Object.keys(targetsObj).reduce((acc, currentKey) => {
        const oneFieldMap = getTargetMap({ [currentKey]: targetsObj[currentKey] }, targetNameMap);
        const [key, value] = Object.entries(oneFieldMap)[0];
        return { ...acc, ...(!!value && { [key]: value }) };
      }, {});

  for (const key in newTargetMap) {
    if (!!newTargetMap[key]) {
      //retrieve first field that is not 'null'.
      obj.targetField = key;
      break;
    }
  }
  return obj;
};

/**
 * Maps field names using the provided target name map.
 *
 * @param {object} fieldObject - An object containing a field to map.
 * @param {object} targetNameMap - A map of target field names.
 * @returns {object} A mapped field object or the original if no mapping is found.
 */
export const getTargetMap = (fieldObject = {}, targetNameMap) => {
  if (!targetNameMap) return fieldObject;
  const [key, value] = Object.entries(fieldObject)[0];
  return !!targetNameMap[key] && !!value ? { [targetNameMap[key]]: value } : fieldObject;
};

/**
 * Debounced function to redirect to the finish view on a specific section of a resume.
 *
 * @param {Object} queryMap - Object containing query parameters.
 * @param {string} locale - Locale for the redirection (optional; default = 'en').
 */
export const redirecToEditView = _.debounce((queryMap, locale = 'en') => {
  const { resumeId, step } = queryMap;
  Push(`/resume?${qs.stringify(queryMap)}`, locale, `/resume/${resumeId}/${step}`);
}, 400);

/**
 * Redirects to a specific item field and focuses on it.
 *
 * @param {object} params - The parameters for redirection.
 * @param {string} params.blockItemId - The ID of the block item.(optional)
 * @param {string} params.targetField - The target field to focus on.
 * @param {string} params.parentWrapperId - The ID of the parent wrapper element that contains the target element. (optional)
 * @param {boolean} params.isDescriptionDraftField - Flag indicating if it is a draft field.(optional)
 */
export const redirectToItemField = ({ blockItemId, targetField, parentWrapperId = '', isDescriptionDraftField }) => {
  const toggleItem = document.getElementById(`toggle-item-${blockItemId}`);
  const blockEditElement = document.getElementById(`custom-title-edit-${blockItemId}`);
  const isCustomSectionTitle = !!blockEditElement;
  const isSingleItemBlock = !toggleItem;
  switch (true) {
    case isCustomSectionTitle:
      triggerEditState(blockEditElement);
      break;
    case isSingleItemBlock:
      if (!isDescriptionDraftField) {
        const exposedField = !blockItemId;
        const singleItemBlockWrapper = document.getElementById(
          parentWrapperId || (exposedField ? 'personal-information' : `single-item-block-${blockItemId}`),
        );
        focusInputFromWrapper(singleItemBlockWrapper, targetField);
      }
      break;
    default:
      triggerToggleItem(toggleItem);
      if (!isDescriptionDraftField) {
        setTimeout(() => {
          const collapsedItem = document.getElementById(parentWrapperId || `collapse-item-${blockItemId}`);
          focusInputFromWrapper(collapsedItem, targetField);
        }, 0);
      }
      break;
  }
};

/**
 * Focuses an input field within a wrapper element.
 *
 * @param {HTMLElement} wrapperElement - The wrapper element containing the input field.
 * @param {string} targetField - The target field to focus on.
 */
export const focusInputFromWrapper = (wrapperElement, targetField) => {
  if (!wrapperElement) return;
  const { element, action, shouldScroll } = getElementFieldFromWrapper(wrapperElement, targetField);
  const method = element?.[action];
  if (element) {
    if (typeof method === 'function') {
      method.call(element);
    }
    if (shouldScroll || action !== 'focus') {
      element?.scrollIntoView({
        block: 'center',
      });
    }
  }
};

/**
 * Checks if an object is an instance of any given constructors.
 *
 * @param {object} object - The object to check.
 * @param {Function[]} constructors - An array of constructors.
 * @returns {boolean} True if the object is an instance of any constructors.
 */
const isInstanceOneOf = (object, constructors) => {
  return constructors.some((constructor) => object instanceof constructor);
};

/**
 * Retrieves an input/element field and associated actions from a wrapper element.
 *
 * @param {HTMLElement} wrapperElement - The wrapper element.
 * @param {string} targetField - The target field to find.
 * @returns {object} An object containing the element, action, and scroll flag.
 */
export const getElementFieldFromWrapper = (wrapperElement, targetField) => {
  let element = null,
    action = '',
    shouldScroll = true;
  const elements = Array.from(wrapperElement?.querySelectorAll('input, textarea,*[name*="interactive-element"]'));
  for (let _element of elements) {
    if (targetField && new RegExp(targetField, 'i').test(_element?.getAttribute('name'))) {
      element = _element;
      break;
    }
  }
  /**
   * Array which contains some HTMLElement constructors that are supposedly focusable.
   */
  const focusableInstances = [HTMLInputElement, HTMLTextAreaElement];
  const elementName = element?.getAttribute('name') || null;
  switch (true) {
    case isInstanceOneOf(element, focusableInstances):
      action = 'focus';
      shouldScroll = false;
      break;
    case elementName && new RegExp('clickable', 'i').test(elementName):
      action = 'click';
      break;
    default:
      shouldScroll = true;
      break;
  }

  return {
    element,
    action,
    shouldScroll,
  };
};

/**
 * Triggers a click on a toggle element to expand it.
 *
 * @param {HTMLElement} toggleElement - The toggle element.
 */
export const triggerToggleItem = (toggleElement) => {
  const { isCollapsed } = toggleElement?.dataset;
  if (isCollapsed === 'false') {
    toggleElement?.click();
  }
};

/**
 * Triggers a click on edit button on block sections to switch on edit mode and focus.
 *
 * @param {HTMLElement} editButton - The edit button element.
 */
export const triggerEditState = (editButton) => {
  const { isEditing } = editButton?.dataset;
  if (isEditing === 'false') {
    editButton?.click();
  }
};

/**
 * Checks if the "click to edit" variant is active.
 *
 * @returns {boolean} True if the "click to edit" variant is active.
 */
export const isWithClickToEditVariant = (currentStep) => {
  switch (currentStep) {
    case 'start':
    case 'experience':
    case 'education':
    case 'skills':
    case 'summary':
    case 'other':
    case 'finish':
      return true;
    default:
      return false;
  }
};

/**
 * Triggers a redirection to the draft editor for a given block item.
 *
 * @param {string} [blockItemId=''] - The ID of the block item.
 */
export const triggerRedirectionToDraftEditor = (blockItemId = '') => {
  const button = document.createElement('button');

  // setting 'click to edit' temporary button's specific attributes
  button.dataset.editorItemId = blockItemId;
  button.id = 'click-to-edit-button';

  button.style.display = 'none';
  document.body.appendChild(button);

  const triggerClick = () => {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    button.dispatchEvent(event);
    button.remove();
  };
  triggerClick();
};

/**
 * Function to handle redirection to a specific field in a block item for editing purposes.
 *
 * @param {string} blockItemId - The ID of the block item to edit.
 * @param {string} targetField - The target field within the block item to redirect to.
 * @param {string} parentWrapperId - The ID of the parent wrapper containing the block item.
 * @param {boolean} isDescriptionDraftField - Flag indicating if the target field uses draft js.
 */
export const clickToEditRedirection = (blockItemId, targetField, parentWrapperId, isDescriptionDraftField) => {
  setTimeout(() => {
    redirectToItemField({
      blockItemId,
      targetField,
      parentWrapperId,
      isDescriptionDraftField,
    });
    if (isDescriptionDraftField) {
      setTimeout(() => {
        triggerRedirectionToDraftEditor(blockItemId);
      }, 0);
    }
  }, 100);
};

/**
 * Retrieves the first non-null field value from the item that exists in the fieldsArray.
 *
 * @param {Object} item - The block item object.
 * @param {Array} fieldsArray - An array of field names to look for in the item.
 * @return {string} - The first non-null field value or an empty string if no match is found.
 */
export const getFirstNonNullField = (item, fieldsArray) => {
  return fieldsArray.find((field) => !!item?.fields?.[field]) || '';
};

/**
 * Checks if a child element is positioned beyond the left edge of its parent element.
 * @param {Element} parentElement - The parent DOM element.
 * @param {Element} childElement - The child DOM element.
 * @returns {boolean} Returns true if the child element is outside the parent's left edge, otherwise false.
 */
export const isChildOutsideParentLeftEdge = (parentElement, childElement) => {
  try {
    if (!parentElement || !childElement) return false;
    const parentRect = parentElement?.getBoundingClientRect();
    const childRect = childElement?.getBoundingClientRect();

    return childRect.left < parentRect.left;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getArabicDefaultFont = (variant) => {
  const fonts = {
    with_tajawal: {
      headingFont: 'tajawal',
      contentFont: 'tajawal',
    },
  };

  return fonts[variant];
};
/**
 * Retrieves the background color of the first parent element
 * with a defined background color, stopping at the "preview-template" element.
 *
 * @param {HTMLElement} element - The starting element to check for background color.
 * @returns {object} - An object containing the parent element with a defined background color and the background color itself,
 *                     or an object with null values if no such parent is found.
 *                     The object structure is { wrapper: HTMLElement|null, backgroundColor: string|null }.
 */
export const getParentUpperLayerBackground = (element) => {
  let currentElement = element?.parentElement;
  const defaultValue = { parentElement: null, backgroundColor: null };
  while (currentElement) {
    if (currentElement?.getAttribute('id') === 'preview-template') {
      return defaultValue;
    }
    const computedStyle = window?.getComputedStyle(currentElement);
    const backgroundColor = computedStyle?.backgroundColor;
    if (backgroundColor && !['rgba(0, 0, 0, 0)', 'transparent'].includes(backgroundColor)) {
      return { parentElement: currentElement, backgroundColor };
    }
    currentElement = currentElement?.parentElement;
  }
  return defaultValue;
};

/**
 * Determines if a given rgba color is dark or not.
 *
 * @param {string} rgbaColor - The rgba color value as a string, e.g., "rgba(0, 0, 0, 1)".
 * @returns {boolean} - Returns true if the color is dark, false if it is light.
 */
export const isColorDark = (rgbaColor) => {
  // Extract the red, green, and blue components from the rgba string.
  const rgbaMatch = rgbaColor?.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
  if (!rgbaMatch) return false;
  try {
    const red = parseInt(rgbaMatch[1], 10);
    const green = parseInt(rgbaMatch[2], 10);
    const blue = parseInt(rgbaMatch[3], 10);

    const brightness = 0.299 * red + 0.587 * green + 0.114 * blue;

    // A common threshold for brightness is 128, above which the color is considered light.
    return brightness < 128;
  } catch (e) {
    console.error('Invalid rgba color:', rgbaColor);
    return false;
  }
};

export const findViewByItemId = (resume, itemId, globalStep = 'finish') => {
  if (!resume) return null;
  const block = findBlockByItemId(resume?.blocks, itemId);
  if (!block) return null;
  const blockType = block?.type;
  switch (blockType) {
    case 'EMPLOYMENT':
      return 'experience';
    case 'EDUCATION':
      return 'education';
    case 'SKILLS':
      return 'skills';
    case 'PROFESSIONAL_SUMMARY':
      return 'summary';
    default:
      return globalStep;
  }
};

export const formatUrl = (url) => {
  if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};
