import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import { staticFile } from '/lib/helpers';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  :root {
    // declare V2 CSS
    --border-transparent: rgba(29, 44, 67, 0.12);
    --transparents-opacity-8: rgba(20, 20, 31, 0.08);
    --transparents-opacity-12: rgba(20, 20, 31, 0.12);
    --transparents-opacity-48: rgba(20, 20, 31, 0.48);
    --text: #1d2c43;
    --primary: #ff6f5b;

    //Border colors
    --border-blue: #0866F5;
    --border-green:#37B982;
    --border-teal:#00B8D9;
    --border-purple:#855AEA;
    --border-steel:#A1B6E3;
    --border-orange:#FFAB00;

    //Chip colors
    --chip-purple: #F6F2FF;
    --chip-blue: #EDF4FF;
    --chip-yellow: #FFF7E9;
    --chip-gray: #ECECED;
    --chip-green: #DDFFF1;

    //additional    
    --additional-colors-purple: #855AEA;
    --additional-colors-blue: #00B8D9;
    --additional-colors-green: #E6FCFF;
    --neutral-values-gray-dark: #8c98aa;
    --neutral-values-gray-light: #aebacc;
    --light-values-light-regular: #e2e8f1;
    --light-values-light-medium: #ecf1f7;
    --light-values-light-extra: #f5f8fc;
    --light-values-white: #ffffff;
    --dark-values-dark-extra: #2e3d54;
    --dark-values-dark-regular: #4f5e75;
    --dark-values-dark-medium: #3e4d64;
    --additional-colors-purple: #855aea;
    --additional-colors-blue: #428eff;
    --additional-colors-green: #27cfb1;
    --additional-colors-green-box: #19CCA3;
    --additional-colors-yellow: #ffc666;
    --additional-colors-red: #f25773;
    --additional-colors-pink: #FFF1F4;
    --additional-colors-orange: #FFF7E9;
    --additional-colors-light-purple: #FBF9FF;
    --additional-colors-steel: #A1B6E3;
    --additional-colors-pink-old: #ff9cae;
    --light-values-white: #ffffff;
    --mid-values-gray-light: #c4c4c7;
    --dark-values-black: #14141f;
    --primary-dark-b500: #0043A6;
    --primary-default-b400: #0050C7;
    --primary-b300: #096AFF;
    --primary-b200: #378EFF;
    --primary-b100: #69AAFF;
    --primary-b75: #C8E0FF;
    --primary-b50:#F1F7FF;
    --secondary-dark-g500: #006F4A;
    --secondary-default-g400: #008B5D;
    --secondary-g300: #37B982;
    --secondary-g200: #5DDFA9;
    --secondary-g100: #83F8C7;
    --secondary-g75: #ADFADA;
    --secondary-g50: #E4FFF4;
    --grayscale-n800: #272731;
    --grayscale-n700: #3A3A43;
    --grayscale-n500: #595964;
    --grayscale-n400: #A1A1A5;
    --grayscale-n300: #C4C4C7;
    --grayscale-n200: #E3E3E4;
    --grayscale-n100: #ECECED;
    --grayscale-n50: #F6F6F6;
    --alerts-warning-light: #FFF7E9;
    --additional-colors-light-blue: #F3F8FF;
    --neutral-values-gray-dark: #8c98aa;
    --neutral-values-gray-light: #aebacc;
    --dark-values-dark-regular: #4c4c55;
    --light-values-light-regular: #e3e3e4;
    --dark-values-dark-extra: #272731;
    --dark-values-dark-medium: #3a3a43;
    --light-values-light-extra: #f6f6f6;
    --light-values-light-regular-v1: #e2e8f1;
    --light-values-light-medium-v1: #ecf1f7;
    --light-values-light-extra-v1: #f5f8fc;
    --dark-values-dark-extra-v1: #2e3d54;
    --dark-values-dark-regular-v1: #4f5e75;
    --dark-values-dark-medium-v1: #3e4d64;
    --black: #000;
  }
  * {
    box-sizing: border-box;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  *:not(input, textarea) {
    user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
  }

  /* transition here is only a fallback solution for older versions of Chrome. */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 0 0 transparent;
  }

  body {
    font-family: ${(p) => p.theme.font.family.correctText};
    font-size: ${(p) => p.theme.font.size.base};
    font-weight: normal;
    line-height: ${(p) => p.theme.font.lineHeight.base};
    letter-spacing: ${(p) => p.theme.font.letterSpacing.base};
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  .DraftEditor-root {
    * {
      -webkit-user-select: text !important;
      user-select: text !important;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.font.family.header};
  }
  h1 {
    font-size: ${(p) => p.theme.font.size.h1}px;
  }
  h2 {
    font-size: ${(p) => p.theme.font.size.h2}px;
  }
  h3 {
    font-size: ${(p) => p.theme.font.size.h3}px;
  }
  h4 {
    font-size: ${(p) => p.theme.font.size.h4}px;
  }
  h5 {
    font-size: ${(p) => p.theme.font.size.h5}px;
  }
  h6 {
    font-size: ${(p) => p.theme.font.size.h6}px;
  }
  p {
    color: ${(p) => p.theme.colors.black};
    font-size: ${(p) => p.theme.font.size.base}px;
  }
  #controllerFrame { display: none !important; }
  .reorder-wrapper-item {
    position: relative;
    transition: top 1s ease-in;
  }
  .pka-panel-footer {
    display: none !important;
  }
  .pka-panel-suggestion-action {
    display: none !important;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${staticFile('fonts/Avenir-Roman.ttf')});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${staticFile('fonts/Avenir-Medium.ttf')});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${staticFile('fonts/Avenir-Heavy.ttf')});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${staticFile('fonts/Avenir-Black.ttf')});
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    src: url(${staticFile('fonts/OpenSans-Regular.ttf')});
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans bold';
    src: url(${staticFile('fonts/OpenSans-Bold.ttf')});
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Tahoma';
    src: url(${staticFile('fonts/Tahoma.ttf')});
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'TTCommons';
    src: url(${staticFile('fonts/TTCommons-Regular.woff2')});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'TTCommons';
    src: url(${staticFile('fonts/TTCommons-DemiBold.woff')});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'TTCommons Bold';
    src: url(${staticFile('fonts/TTCommons-Bold.woff2')});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'TTCommons DemiBold';
    src: url(${staticFile('fonts/TTCommons-DemiBold.woff')});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${staticFile('fonts/Montserrat-Regular.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${staticFile('fonts/Montserrat-Bold.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat Light';
    src: url(${staticFile('fonts/Montserrat-Light.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat Medium';
    src: url(${staticFile('fonts/Montserrat-Medium.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat SemiBold';
    src: url(${staticFile('fonts/Montserrat-SemiBold.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat BoldItalic';
    src: url(${staticFile('fonts/Montserrat-BoldItalic.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Arial Bold';
    src: url(${staticFile('fonts/Arial-Bold.woff')});
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Arial Italic';
    src: url(${staticFile('fonts/Arial-Italic.woff')});
    font-style: italic;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Arial BoldItalic';
    src: url(${staticFile('fonts/Arial-BoldItalic.woff')});
    font-style: italic;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${staticFile('fonts/Roboto-Regular.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Medium';
    src: url(${staticFile('fonts/Roboto-Medium.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Italic';
    src: url(${staticFile('fonts/Roboto-Italic.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Bold';
    src: url(${staticFile('fonts/Roboto-Bold.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto BoldItalic';
    src: url(${staticFile('fonts/Roboto-BoldItalic.ttf')});
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Black';
    src: url(${staticFile('fonts/Roboto-Black.ttf')});
    font-display: swap;
  }
  @font-face {
    font-display: swap;
    font-family: 'Open Sans';
    src: url(${staticFile('fonts/OpenSans-Regular.ttf')});
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-display: swap;
    font-family: 'Open Sans bold';
    src: url(${staticFile('fonts/OpenSans-Bold.ttf')});
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-display: swap;
    font-family: 'Gilroy Black';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Black.woff2')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'Gilroy Bold';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Bold.woff2')});
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'Gilroy ExtraBold';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Extra-Bold.woff2')});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'Gilroy Medium';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Medium.woff2')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'Gilroy Regular';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Regular.woff2')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'Gilroy SemiBold';
    src: url(${staticFile('fonts/Gilroy/Gilroy-Semi-Bold.woff2')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'IBM Plex Bold';
    src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Bold.ttf')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'IBM Plex Medium';
    src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Medium.ttf')});
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'IBM Plex Regular';
    src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Regular.ttf')});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'IBM Plex SemiBold';
    src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf')});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-display: swap;
    font-family: 'Merriweather Regular';
    src: url(${staticFile('fonts/Merriweather-Regular.ttf')});
  }
  @font-face {
    font-display: swap;
    font-family: 'Merriweather Italic';
    src: url(${staticFile('fonts/Merriweather-Italic.ttf')});
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(${staticFile(
      'fonts/Poppins/pxiEyp8kv8JHgFVrJJbecmNE.woff2',
    )}) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }

  /* latin-ext */
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(${staticFile(
      'fonts/Poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2',
    )}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(${staticFile(
      'fonts/Poppins/pxiEyp8kv8JHgFVrJJfecg.woff2',
    )}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-display:block;src: local("Poppins Medium Italic"),local("Poppins-MediumItalic"),url(${staticFile(
      'fonts/Poppins/pxiDyp8kv8JHgFVrJJLmg1hVFteOcEg.woff2',
    )}) format("woff2");
    unicode-range: u+0900-097f,u+1cd0-1cf6,u+1cf8-1cf9,u+200c-200d,u+20a8,u+20b9,u+25cc,u+a830-a839,u+a8e0-a8fb
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-display:block;src: local("Poppins Medium Italic"),local("Poppins-MediumItalic"),url(${staticFile(
      'fonts/Poppins/pxiDyp8kv8JHgFVrJJLmg1hVGdeOcEg.woff2',
    )}) format("woff2");
    unicode-range: u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-display:block;src: local("Poppins Medium Italic"),local("Poppins-MediumItalic"),url(${staticFile(
      'fonts/Poppins/pxiDyp8kv8JHgFVrJJLmg1hVF9eO.woff2',
    )}) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: local("Poppins Medium"), local("Poppins-Medium"), url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2',
    )}) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-display: block;
    src:local("Poppins SemiBold"),local("Poppins-SemiBold"),url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2',
    )}) format("woff2");
    unicode-range:u+0900-097f,u+1cd0-1cf6,u+1cf8-1cf9,u+200c-200d,u+20a8,u+20b9,u+25cc,u+a830-a839,u+a8e0-a8fb
  }
  @font-face {
      font-display: swap;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-display: block;
      src:local("Poppins SemiBold"),local("Poppins-SemiBold"),url(${staticFile(
        'fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2',
      )}) format("woff2");
      unicode-range:u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff
  }
  @font-face {
      font-display: swap;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-display: block;
      src:local("Poppins SemiBold"),local("Poppins-SemiBold"),url(${staticFile(
        'fonts/Poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2',
      )}) format("woff2");
      unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd;
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-display: block;src: local("Poppins Bold"),local("Poppins-Bold"),url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2',
    )}) format("woff2");
    unicode-range: u+0900-097f,u+1cd0-1cf6,u+1cf8-1cf9,u+200c-200d,u+20a8,u+20b9,u+25cc,u+a830-a839,u+a8e0-a8fb;
  }
  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-display: block;src: local("Poppins Bold"),local("Poppins-Bold"),url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2',
    )}) format("woff2");
    unicode-range: u+0100-024f,u+0259,u+1e??,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff;
    font-display: swap;
  }
  @font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-display: block;src: local("Poppins Bold"),local("Poppins-Bold"),url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2',
    )}) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd;
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2',
    )}) format("woff2");
    unicode-range: u+0900-097f, u+1cd0-1cf6, u+1cf8-1cf9, u+200c-200d, u+20a8, u+20b9, u+25cc, u+a830-a839, u+a8e0-a8fb;
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2',
    )}) format("woff2");
    unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff;
  }

  @font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url(${staticFile(
      'fonts/Poppins/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2',
    )}) format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }
  @font-face{
    font-family: ProductSans;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${staticFile('fonts/ProductSans/ProductSans-Regular.ttf')});
  }
  @font-face{
    font-family: ProductSans;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${staticFile('fonts/ProductSans/ProductSans-Bold.ttf')});
  }
  @font-face{
    font-family: ProductSans;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${staticFile('fonts/ProductSans/ProductSans-Medium.ttf')});
  }
  @font-face{
    font-family: ProductSans;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${staticFile('fonts/ProductSans/ProductSans-Light.ttf')});
  }

  @font-face{
    font-family: ClashGrotesk;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${staticFile('fonts/ClashGrotesk/ClashGrotesk-Light.woff')});
  }
  @font-face{
    font-family: ClashGrotesk;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${staticFile('fonts/ClashGrotesk/ClashGrotesk-Regular.woff')});
  }
  @font-face{
    font-family: ClashGrotesk;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${staticFile('fonts/ClashGrotesk/ClashGrotesk-Medium.woff')});
  }
  @font-face{
    font-family: ClashGrotesk;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${staticFile('fonts/ClashGrotesk/ClashGrotesk-Bold.woff')});
  }
  @font-face{
    font-family: ClashGrotesk;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${staticFile('fonts/ClashGrotesk/ClashGrotesk-Semibold.woff')});
  }
 
  

  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-Light.ttf')});
  }
  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-Regular.ttf')});
  }
  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-Medium.ttf')});
  }
  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-SemiBold.ttf')});
  }
  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-Bold.ttf')});
  }
  @font-face{
    font-family: Sora;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(${staticFile('fonts/Sora/Sora-ExtraBold.ttf')});
  }
  @font-face {
      font-family: 'Rubik';
      src: url(${staticFile('fonts/Rubik-Regular.ttf')});
      font-weight: 400;
    }
    @font-face {
      font-family: 'Rubik Bold';
      src: url(${staticFile('fonts/Rubik-Bold.ttf')});
      font-weight: 700;
    }
    @font-face {
      font-family: 'Rubik Medium';
      src: url(${staticFile('fonts/Rubik-Medium.ttf')});
      font-weight: 500;
    }
    @font-face {
      font-family: 'Rubik SemiBold';
      src: url(${staticFile('fonts/Rubik-SemiBold.ttf')});
      font-weight: 600;
    }
    @font-face {
      font-family: 'Rubik Light';
      src: url(${staticFile('fonts/Rubik-Light.ttf')});
      font-weight: 300;
    }
`;

export default GlobalStyle;
