import React from 'react';
import { getDomainName, getBrandName, getContactEmail } from 'lib/helpers';

import env from '/env';
import { useResponsive } from '/imports/core/api/responsiveContext';

const useDomain = () => {
  const { host } = useResponsive();

  const getDocxEndpoint = () => {
    return env.DOCX_ENDPOINT;
  };

  return {
    getDomainName: () => getDomainName(host),
    getBrandName: (isSolid, currentHost) => getBrandName(isSolid, currentHost || host),
    getContactEmail: () => getContactEmail(host),
    docxEndpoint: getDocxEndpoint(),
  };
};

export const withDomain = (Component) => {
  function Wrapper(props) {
    const domainDetail = useDomain();
    return <Component {...domainDetail} {...props} />;
  }

  Wrapper.getInitialProps = async (ctx) => {
    const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
    return { ...pageProps };
  };

  return Wrapper;
};

export default useDomain;
