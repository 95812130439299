import styled, { css } from 'styled-components';

export const View = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default View;
