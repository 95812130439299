import { css } from 'styled-components';

import { BREAKPOINTS } from '/styles/variables';

const emSize = (pixelValue) => `${pixelValue / 16}em`;
const getGridRelatedValue = (value) => {
  if (value && typeof value === 'number') {
    return `${(value / 12) * 100}%`;
  }
  return value;
};

export const min =
  (breakpoint) =>
  (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(breakpoint, BREAKPOINTS)}) {
      ${css(...args)};
    }
  `;

export const max =
  (breakpoint) =>
  (...args) => css`
    @media (max-width: ${getSizeFromBreakpoint(breakpoint, BREAKPOINTS)}) {
      ${css(...args)};
    }
  `;

export const between =
  (firstBreakpoint, secondBreakpoint) =>
  (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(firstBreakpoint, BREAKPOINTS)}) and (max-width: ${getSizeFromBreakpoint(
        secondBreakpoint,
        BREAKPOINTS,
      )}) {
      ${css(...args)};
    }
  `;

export const larger = (...args) => css`
  @media (max-width: 2300px) {
    ${css(...args)};
  }
`;

export const medium = (...args) => css`
  @media (max-width: 1366px) {
    ${css(...args)};
  }
`;

export const smaller = (...args) => css`
  @media (max-width: 980px) {
    ${css(...args)};
  }
`;

export const getOnlyMobile = () => {
  const { innerWidth } = window;
  if (innerWidth <= BREAKPOINTS['xs']) return 'xs';
  return null;
};

export const getCurrentBreakpoint = () => {
  const { innerWidth } = window;
  if (innerWidth <= BREAKPOINTS['sm']) {
    return 'xs';
  } else if (innerWidth >= BREAKPOINTS['sm'] && innerWidth < BREAKPOINTS['md']) {
    return 'sm';
  } else if (innerWidth >= BREAKPOINTS['md'] && innerWidth < BREAKPOINTS['lg']) {
    return 'md';
  } else if (innerWidth >= BREAKPOINTS['lg']) {
    return 'lg';
  }
};

export const isGeneratorMobileView = () => {
  try {
    const { innerWidth } = window;
    return innerWidth <= 800;
  } catch (e) {
    return false;
  }
};

export const getSizeFromBreakpoint = (breakpointValue) => {
  if (BREAKPOINTS[breakpointValue]) {
    return emSize(BREAKPOINTS[breakpointValue]);
  } else if (parseInt(breakpointValue, 10)) {
    return emSize(BREAKPOINTS[breakpointValue]);
  }
  console.error('styled-media-query: No valid breakpoint or size specified for media.');
  return '0';
};

export const buildResponsiveWidthMixin = (props) => {
  let res = '';
  if (props.md != null || props.lg != null || props.sm != null || props.xs != null) {
    if (props.lg != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('lg')}) {
          width: ${getGridRelatedValue(props.lg)};
        }
      `;
    }
    if (props.md != null) {
      res += `
        @media (min-width: ${getSizeFromBreakpoint('md')}) {
          width: ${getGridRelatedValue(props.md)};
        }
      `;
      res += `
        @media (max-width: ${getSizeFromBreakpoint('md')}) {
          width: 100%;
        }
      `;
    }
    if (props.sm != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('md')}) {
          width: ${getGridRelatedValue(props.sm)};
        }
      `;
    }
    if (props.xs != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('xs')}) {
          width: ${getGridRelatedValue(props.xs)};
        }
      `;
    }
  }
  if (props.mdPush != null || props.lgPush != null || props.smPush != null || props.xsPush != null) {
    if (props.lgPush != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('lg')}) {
          margin-left: ${getGridRelatedValue(props.lgPush)};
        }
      `;
    }
    if (props.mdPush != null) {
      res += `
        @media (min-width: ${getSizeFromBreakpoint('md')}) {
          margin-left: ${getGridRelatedValue(props.mdPush)};
        }
      `;
    }
    if (props.smPush != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('md')}) {
          margin-left: ${getGridRelatedValue(props.smPush)};
        }
      `;
    }
    if (props.xsPush != null) {
      res += `
        @media (max-width: ${getSizeFromBreakpoint('xs')}) {
          margin-left: ${getGridRelatedValue(props.xsPush)};
        }
      `;
    }
  }
  return res;
};
