import gql from 'graphql-tag';

import { userFull } from '/imports/core/api/apollo/client/fragments';

export const CURRENT_USER = gql`
  query {
    userInfo {
      ...UserFull
    }
  }
  ${userFull}
`;
