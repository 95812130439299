const options = [
  {
    title: 'بكالوريوس العلوم في الرياضيات',
    value: 'بكالوريوس العلوم في الرياضيات',
  },
  {
    title: 'بكالوريوس العلوم في الفيزياء',
    value: 'بكالوريوس العلوم في الفيزياء',
  },
  {
    title: 'بكالوريوس العلوم في الكيمياء',
    value: 'بكالوريوس العلوم في الكيمياء',
  },
  {
    title: 'بكالوريوس العلوم في الأحياء',
    value: 'بكالوريوس العلوم في الأحياء',
  },
  {
    title: 'بكالوريوس العلوم في علوم الأرض',
    value: 'بكالوريوس العلوم في علوم الأرض',
  },
  {
    title: 'بكالوريوس العلوم في علم الفلك',
    value: 'بكالوريوس العلوم في علم الفلك',
  },
  {
    title: 'بكالوريوس العلوم في علم البيئة',
    value: 'بكالوريوس العلوم في علم البيئة',
  },
  {
    title: 'بكالوريوس العلوم في الجيولوجيا',
    value: 'بكالوريوس العلوم في الجيولوجيا',
  },
  {
    title: 'بكالوريوس العلوم في الفيزياء الطبية',
    value: 'بكالوريوس العلوم في الفيزياء الطبية',
  },
  {
    title: 'بكالوريوس العلوم في البيوتكنولوجي',
    value: 'بكالوريوس العلوم في البيوتكنولوجي',
  },
  {
    title: 'بكالوريوس العلوم في الإحصاء',
    value: 'بكالوريوس العلوم في الإحصاء',
  },
  {
    title: 'بكالوريوس العلوم في علم النفس',
    value: 'بكالوريوس العلوم في علم النفس',
  },
  {
    title: 'بكالوريوس العلوم في علم الاجتماع',
    value: 'بكالوريوس العلوم في علم الاجتماع',
  },
  {
    title: 'بكالوريوس العلوم في علم النفس العيادي',
    value: 'بكالوريوس العلوم في علم النفس العيادي',
  },
  {
    title: 'بكالوريوس العلوم في الخدمة الاجتماعية',
    value: 'بكالوريوس العلوم في الخدمة الاجتماعية',
  },
  {
    title: 'بكالوريوس العلوم في الأنثروبولوجيا',
    value: 'بكالوريوس العلوم في الأنثروبولوجيا',
  },
  {
    title: 'بكالوريوس العلوم في العلوم السياسية',
    value: 'بكالوريوس العلوم في العلوم السياسية',
  },
  {
    title: 'بكالوريوس العلوم في العلاقات الدولية',
    value: 'بكالوريوس العلوم في العلاقات الدولية',
  },
  {
    title: 'بكالوريوس العلوم في الإعلام',
    value: 'بكالوريوس العلوم في الإعلام',
  },
  {
    title: 'بكالوريوس العلوم في الاتصال الجماهيري',
    value: 'بكالوريوس العلوم في الاتصال الجماهيري',
  },
  {
    title: 'بكالوريوس العلوم في اللغة العربية',
    value: 'بكالوريوس العلوم في اللغة العربية',
  },
  {
    title: 'بكالوريوس العلوم في اللغة الإنجليزية',
    value: 'بكالوريوس العلوم في اللغة الإنجليزية',
  },
  {
    title: 'بكالوريوس العلوم في اللغات والترجمة',
    value: 'بكالوريوس العلوم في اللغات والترجمة',
  },
  {
    title: 'بكالوريوس العلوم في الأدب',
    value: 'بكالوريوس العلوم في الأدب',
  },
  {
    title: 'بكالوريوس العلوم في التاريخ',
    value: 'بكالوريوس العلوم في التاريخ',
  },
  {
    title: 'بكالوريوس العلوم في الجغرافيا',
    value: 'بكالوريوس العلوم في الجغرافيا',
  },
  {
    title: 'بكالوريوس العلوم في الفلسفة',
    value: 'بكالوريوس العلوم في الفلسفة',
  },
  {
    title: 'بكالوريوس العلوم في الشريعة الإسلامية',
    value: 'بكالوريوس العلوم في الشريعة الإسلامية',
  },
  {
    title: 'بكالوريوس العلوم في الفقه',
    value: 'بكالوريوس العلوم في الفقه',
  },
  {
    title: 'بكالوريوس العلوم في أصول الدين',
    value: 'بكالوريوس العلوم في أصول الدين',
  },
  {
    title: 'بكالوريوس العلوم في الدعوة والإعلام الإسلامي',
    value: 'بكالوريوس العلوم في الدعوة والإعلام الإسلامي',
  },
  {
    title: 'بكالوريوس العلوم في القرآن وعلومه',
    value: 'بكالوريوس العلوم في القرآن وعلومه',
  },
  {
    title: 'بكالوريوس العلوم في الحديث وعلومه',
    value: 'بكالوريوس العلوم في الحديث وعلومه',
  },
  {
    title: 'بكالوريوس العلوم في العقيدة الإسلامية',
    value: 'بكالوريوس العلوم في العقيدة الإسلامية',
  },
  {
    title: 'بكالوريوس العلوم في الاقتصاد الإسلامي',
    value: 'بكالوريوس العلوم في الاقتصاد الإسلامي',
  },
  {
    title: 'بكالوريوس العلوم في الإدارة',
    value: 'بكالوريوس العلوم في الإدارة',
  },
  {
    title: 'بكالوريوس العلوم في الإدارة العامة',
    value: 'بكالوريوس العلوم في الإدارة العامة',
  },
  {
    title: 'بكالوريوس العلوم في إدارة الأعمال',
    value: 'بكالوريوس العلوم في إدارة الأعمال',
  },
  {
    title: 'بكالوريوس العلوم في المحاسبة',
    value: 'بكالوريوس العلوم في المحاسبة',
  },
  {
    title: 'بكالوريوس العلوم في التسويق',
    value: 'بكالوريوس العلوم في التسويق',
  },
  {
    title: 'بكالوريوس العلوم في الإدارة المالية',
    value: 'بكالوريوس العلوم في الإدارة المالية',
  },
  {
    title: 'بكالوريوس العلوم في نظم المعلومات الإدارية',
    value: 'بكالوريوس العلوم في نظم المعلومات الإدارية',
  },
  {
    title: 'بكالوريوس العلوم في الاقتصاد',
    value: 'بكالوريوس العلوم في الاقتصاد',
  },
  {
    title: 'بكالوريوس العلوم في الاقتصاد والمالية',
    value: 'بكالوريوس العلوم في الاقتصاد والمالية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم المالية والمصرفية',
    value: 'بكالوريوس العلوم في العلوم المالية والمصرفية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم القانونية',
    value: 'بكالوريوس العلوم في العلوم القانونية',
  },
  {
    title: 'بكالوريوس العلوم في القانون الدولي',
    value: 'بكالوريوس العلوم في القانون الدولي',
  },
  {
    title: 'بكالوريوس العلوم في القانون التجاري',
    value: 'بكالوريوس العلوم في القانون التجاري',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة المدنية',
    value: 'بكالوريوس العلوم في الهندسة المدنية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الميكانيكية',
    value: 'بكالوريوس العلوم في الهندسة الميكانيكية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الكهربائية',
    value: 'بكالوريوس العلوم في الهندسة الكهربائية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الكيميائية',
    value: 'بكالوريوس العلوم في الهندسة الكيميائية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الصناعية',
    value: 'بكالوريوس العلوم في الهندسة الصناعية',
  },
  {
    title: 'بكالوريوس العلوم في هندسة الحاسبات',
    value: 'بكالوريوس العلوم في هندسة الحاسبات',
  },
  {
    title: 'بكالوريوس العلوم في هندسة الاتصالات',
    value: 'بكالوريوس العلوم في هندسة الاتصالات',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة البيئية',
    value: 'بكالوريوس العلوم في الهندسة البيئية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة المعمارية',
    value: 'بكالوريوس العلوم في الهندسة المعمارية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الجيوتقنية',
    value: 'بكالوريوس العلوم في الهندسة الجيوتقنية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الطبية الحيوية',
    value: 'بكالوريوس العلوم في الهندسة الطبية الحيوية',
  },
  {
    title: 'بكالوريوس العلوم في الطب البشري',
    value: 'بكالوريوس العلوم في الطب البشري',
  },
  {
    title: 'بكالوريوس العلوم في طب الأسنان',
    value: 'بكالوريوس العلوم في طب الأسنان',
  },
  {
    title: 'بكالوريوس العلوم في الصيدلة',
    value: 'بكالوريوس العلوم في الصيدلة',
  },
  {
    title: 'بكالوريوس العلوم في العلاج الطبيعي',
    value: 'بكالوريوس العلوم في العلاج الطبيعي',
  },
  {
    title: 'بكالوريوس العلوم في التمريض',
    value: 'بكالوريوس العلوم في التمريض',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الصحية',
    value: 'بكالوريوس العلوم في العلوم الصحية',
  },
  {
    title: 'بكالوريوس العلوم في الصحة العامة',
    value: 'بكالوريوس العلوم في الصحة العامة',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الطبية التطبيقية',
    value: 'بكالوريوس العلوم في العلوم الطبية التطبيقية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الطبية الأساسية',
    value: 'بكالوريوس العلوم في العلوم الطبية الأساسية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الطبية المخبرية',
    value: 'بكالوريوس العلوم في العلوم الطبية المخبرية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الصحية البيئية',
    value: 'بكالوريوس العلوم في العلوم الصحية البيئية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الغذائية والتغذية',
    value: 'بكالوريوس العلوم في العلوم الغذائية والتغذية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الرياضية',
    value: 'بكالوريوس العلوم في العلوم الرياضية',
  },
  {
    title: 'بكالوريوس العلوم في علوم الحاسب',
    value: 'بكالوريوس العلوم في علوم الحاسب',
  },
  {
    title: 'بكالوريوس العلوم في هندسة البرمجيات',
    value: 'بكالوريوس العلوم في هندسة البرمجيات',
  },
  {
    title: 'بكالوريوس العلوم في أمن المعلومات',
    value: 'بكالوريوس العلوم في أمن المعلومات',
  },
  {
    title: 'بكالوريوس العلوم في الذكاء الاصطناعي',
    value: 'بكالوريوس العلوم في الذكاء الاصطناعي',
  },
  {
    title: 'بكالوريوس العلوم في نظم المعلومات',
    value: 'بكالوريوس العلوم في نظم المعلومات',
  },
  {
    title: 'بكالوريوس العلوم في تقنية المعلومات',
    value: 'بكالوريوس العلوم في تقنية المعلومات',
  },
  {
    title: 'بكالوريوس العلوم في الشبكات وأمن المعلومات',
    value: 'بكالوريوس العلوم في الشبكات وأمن المعلومات',
  },
  {
    title: 'بكالوريوس العلوم في الوسائط المتعددة وتطويرالويب',
    value: 'بكالوريوس العلوم في الوسائط المتعددة وتطويرالويب',
  },
  {
    title: 'بكالوريوس العلوم في العمارة الداخلية',
    value: 'بكالوريوس العلوم في العمارة الداخلية',
  },
  {
    title: 'بكالوريوس العلوم في التصميم الجرافيكي',
    value: 'بكالوريوس العلوم في التصميم الجرافيكي',
  },
  {
    title: 'بكالوريوس العلوم في التصميم الصناعي',
    value: 'بكالوريوس العلوم في التصميم الصناعي',
  },
  {
    title: 'بكالوريوس العلوم في التصميم الأزياء',
    value: 'بكالوريوس العلوم في التصميم الأزياء',
  },
  {
    title: 'بكالوريوس العلوم في الفنون الجميلة',
    value: 'بكالوريوس العلوم في الفنون الجميلة',
  },
  {
    title: 'بكالوريوس العلوم في الموسيقى',
    value: 'بكالوريوس العلوم في الموسيقى',
  },
  {
    title: 'بكالوريوس العلوم في الفنون المسرحية',
    value: 'بكالوريوس العلوم في الفنون المسرحية',
  },
  {
    title: 'بكالوريوس العلوم في السينما والإنتاج الفيلمي',
    value: 'بكالوريوس العلوم في السينما والإنتاج الفيلمي',
  },
  {
    title: 'بكالوريوس العلوم في الإنتاج الإعلامي',
    value: 'بكالوريوس العلوم في الإنتاج الإعلامي',
  },
  {
    title: 'بكالوريوس العلوم في الإخراج السينمائي',
    value: 'بكالوريوس العلوم في الإخراج السينمائي',
  },
  {
    title: 'بكالوريوس العلوم في الفنون البصرية',
    value: 'بكالوريوس العلوم في الفنون البصرية',
  },
  {
    title: 'بكالوريوس العلوم في الفنون التطبيقية',
    value: 'بكالوريوس العلوم في الفنون التطبيقية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الزراعية',
    value: 'بكالوريوس العلوم في العلوم الزراعية',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة الزراعية',
    value: 'بكالوريوس العلوم في الهندسة الزراعية',
  },
  {
    title: 'بكالوريوس العلوم في الإنتاج الحيواني',
    value: 'بكالوريوس العلوم في الإنتاج الحيواني',
  },
  {
    title: 'بكالوريوس العلوم في الإنتاج النباتي',
    value: 'بكالوريوس العلوم في الإنتاج النباتي',
  },
  {
    title: 'بكالوريوس العلوم في العلوم البيطرية',
    value: 'بكالوريوس العلوم في العلوم البيطرية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم البحرية',
    value: 'بكالوريوس العلوم في العلوم البحرية',
  },
  {
    title: 'بكالوريوس العلوم في علوم الأغذية',
    value: 'بكالوريوس العلوم في علوم الأغذية',
  },
  {
    title: 'بكالوريوس العلوم في العلوم البيئية',
    value: 'بكالوريوس العلوم في العلوم البيئية',
  },
  {
    title: 'بكالوريوس العلوم في السياحة والضيافة',
    value: 'بكالوريوس العلوم في السياحة والضيافة',
  },
  {
    title: 'بكالوريوس العلوم في إدارة الفنادق',
    value: 'بكالوريوس العلوم في إدارة الفنادق',
  },
  {
    title: 'بكالوريوس العلوم في السياحة والسفر',
    value: 'بكالوريوس العلوم في السياحة والسفر',
  },
  {
    title: 'بكالوريوس العلوم في العلوم الرياضية والترفيهية',
    value: 'بكالوريوس العلوم في العلوم الرياضية والترفيهية',
  },
  {
    title: 'بكالوريوس العلوم في التربية البدنية وعلوم الرياضة',
    value: 'بكالوريوس العلوم في التربية البدنية وعلوم الرياضة',
  },
  {
    title: 'بكالوريوس الطب',
    value: 'بكالوريوس الطب',
  },
  {
    title: 'بكالوريوس الجراحة',
    value: 'بكالوريوس الجراحة',
  },
  {
    title: 'بكالوريوس طب الأسنان',
    value: 'بكالوريوس طب الأسنان',
  },
  {
    title: 'بكالوريوس الصيدلة',
    value: 'بكالوريوس الصيدلة',
  },
  {
    title: 'بكالوريوس الهندسة المدنية',
    value: 'بكالوريوس الهندسة المدنية',
  },
  {
    title: 'بكالوريوس الهندسة الكهربائية',
    value: 'بكالوريوس الهندسة الكهربائية',
  },
  {
    title: 'بكالوريوس الهندسة الميكانيكية',
    value: 'بكالوريوس الهندسة الميكانيكية',
  },
  {
    title: 'بكالوريوس الهندسة الكيميائية',
    value: 'بكالوريوس الهندسة الكيميائية',
  },
  {
    title: 'بكالوريوس الهندسة الصناعية',
    value: 'بكالوريوس الهندسة الصناعية',
  },
  {
    title: 'بكالوريوس الهندسة الحاسوبية',
    value: 'بكالوريوس الهندسة الحاسوبية',
  },
  {
    title: 'بكالوريوس علوم الحاسب',
    value: 'بكالوريوس علوم الحاسب',
  },
  {
    title: 'بكالوريوس نظم المعلومات',
    value: 'بكالوريوس نظم المعلومات',
  },
  {
    title: 'بكالوريوس تقنية المعلومات',
    value: 'بكالوريوس تقنية المعلومات',
  },
  {
    title: 'بكالوريوس العلوم الإدارية',
    value: 'بكالوريوس العلوم الإدارية',
  },
  {
    title: 'بكالوريوس إدارة الأعمال',
    value: 'بكالوريوس إدارة الأعمال',
  },
  {
    title: 'بكالوريوس المحاسبة',
    value: 'بكالوريوس المحاسبة',
  },
  {
    title: 'بكالوريوس التسويق',
    value: 'بكالوريوس التسويق',
  },
  {
    title: 'بكالوريوس الموارد البشرية',
    value: 'بكالوريوس الموارد البشرية',
  },
  {
    title: 'بكالوريوس الاقتصاد',
    value: 'بكالوريوس الاقتصاد',
  },
  {
    title: 'بكالوريوس العلوم المالية',
    value: 'بكالوريوس العلوم المالية',
  },
  {
    title: 'بكالوريوس العلوم السياسية',
    value: 'بكالوريوس العلوم السياسية',
  },
  {
    title: 'بكالوريوس العلاقات الدولية',
    value: 'بكالوريوس العلاقات الدولية',
  },
  {
    title: 'بكالوريوس القانون',
    value: 'بكالوريوس القانون',
  },
  {
    title: 'بكالوريوس الشريعة',
    value: 'بكالوريوس الشريعة',
  },
  {
    title: 'بكالوريوس الدراسات الإسلامية',
    value: 'بكالوريوس الدراسات الإسلامية',
  },
  {
    title: 'بكالوريوس التاريخ',
    value: 'بكالوريوس التاريخ',
  },
  {
    title: 'بكالوريوس الجغرافيا',
    value: 'بكالوريوس الجغرافيا',
  },
  {
    title: 'بكالوريوس الأدب العربي',
    value: 'بكالوريوس الأدب العربي',
  },
  {
    title: 'بكالوريوس اللغة الإنجليزية',
    value: 'بكالوريوس اللغة الإنجليزية',
  },
  {
    title: 'بكالوريوس اللغات والترجمة',
    value: 'بكالوريوس اللغات والترجمة',
  },
  {
    title: 'بكالوريوس الإعلام',
    value: 'بكالوريوس الإعلام',
  },
  {
    title: 'بكالوريوس الصحافة',
    value: 'بكالوريوس الصحافة',
  },
  {
    title: 'بكالوريوس العلاقات العامة',
    value: 'بكالوريوس العلاقات العامة',
  },
  {
    title: 'بكالوريوس الإذاعة والتلفزيون',
    value: 'بكالوريوس الإذاعة والتلفزيون',
  },
  {
    title: 'بكالوريوس الفنون الجميلة',
    value: 'بكالوريوس الفنون الجميلة',
  },
  {
    title: 'بكالوريوس التصميم الجرافيكي',
    value: 'بكالوريوس التصميم الجرافيكي',
  },
  {
    title: 'بكالوريوس التصميم الداخلي',
    value: 'بكالوريوس التصميم الداخلي',
  },
  {
    title: 'بكالوريوس العمارة',
    value: 'بكالوريوس العمارة',
  },
  {
    title: 'بكالوريوس التخطيط العمراني',
    value: 'بكالوريوس التخطيط العمراني',
  },
  {
    title: 'بكالوريوس العلوم الزراعية',
    value: 'بكالوريوس العلوم الزراعية',
  },
  {
    title: 'بكالوريوس الطب البيطري',
    value: 'بكالوريوس الطب البيطري',
  },
  {
    title: 'بكالوريوس العلوم البيئية',
    value: 'بكالوريوس العلوم البيئية',
  },
  {
    title: 'بكالوريوس البيولوجيا',
    value: 'بكالوريوس البيولوجيا',
  },
  {
    title: 'بكالوريوس الكيمياء',
    value: 'بكالوريوس الكيمياء',
  },
  {
    title: 'بكالوريوس الفيزياء',
    value: 'بكالوريوس الفيزياء',
  },
  {
    title: 'بكالوريوس الجيولوجيا',
    value: 'بكالوريوس الجيولوجيا',
  },
  {
    title: 'بكالوريوس الرياضيات',
    value: 'بكالوريوس الرياضيات',
  },
  {
    title: 'بكالوريوس الإحصاء',
    value: 'بكالوريوس الإحصاء',
  },
  {
    title: 'بكالوريوس علم النفس',
    value: 'بكالوريوس علم النفس',
  },
  {
    title: 'بكالوريوس علم الاجتماع',
    value: 'بكالوريوس علم الاجتماع',
  },
  {
    title: 'بكالوريوس الخدمة الاجتماعية',
    value: 'بكالوريوس الخدمة الاجتماعية',
  },
  {
    title: 'بكالوريوس التربية',
    value: 'بكالوريوس التربية',
  },
  {
    title: 'بكالوريوس التعليم الابتدائي',
    value: 'بكالوريوس التعليم الابتدائي',
  },
  {
    title: 'بكالوريوس التعليم الثانوي',
    value: 'بكالوريوس التعليم الثانوي',
  },
  {
    title: 'بكالوريوس التربية الخاصة',
    value: 'بكالوريوس التربية الخاصة',
  },
  {
    title: 'بكالوريوس التربية البدنية',
    value: 'بكالوريوس التربية البدنية',
  },
  {
    title: 'بكالوريوس العلوم الرياضية',
    value: 'بكالوريوس العلوم الرياضية',
  },
  {
    title: 'بكالوريوس التغذية وعلوم الأطعمة',
    value: 'بكالوريوس التغذية وعلوم الأطعمة',
  },
  {
    title: 'بكالوريوس الصحة العامة',
    value: 'بكالوريوس الصحة العامة',
  },
  {
    title: 'بكالوريوس العلوم الطبية التطبيقية',
    value: 'بكالوريوس العلوم الطبية التطبيقية',
  },
  {
    title: 'بكالوريوس التمريض',
    value: 'بكالوريوس التمريض',
  },
  {
    title: 'بكالوريوس العلاج الطبيعي',
    value: 'بكالوريوس العلاج الطبيعي',
  },
  {
    title: 'بكالوريوس العلاج الوظيفي',
    value: 'بكالوريوس العلاج الوظيفي',
  },
  {
    title: 'بكالوريوس العلاج النفسي',
    value: 'بكالوريوس العلاج النفسي',
  },
  {
    title: 'بكالوريوس العلاج الإشعاعي',
    value: 'بكالوريوس العلاج الإشعاعي',
  },
  {
    title: 'بكالوريوس العلوم الصحية',
    value: 'بكالوريوس العلوم الصحية',
  },
  {
    title: 'بكالوريوس العلوم الطبية الأساسية',
    value: 'بكالوريوس العلوم الطبية الأساسية',
  },
  {
    title: 'بكالوريوس العلوم الطبية الحيوية',
    value: 'بكالوريوس العلوم الطبية الحيوية',
  },
  {
    title: 'بكالوريوس الأشعة التشخيصية',
    value: 'بكالوريوس الأشعة التشخيصية',
  },
  {
    title: 'بكالوريوس العلوم العسكرية',
    value: 'بكالوريوس العلوم العسكرية',
  },
  {
    title: 'بكالوريوس الأمن السيبراني',
    value: 'بكالوريوس الأمن السيبراني',
  },
  {
    title: 'بكالوريوس الدفاع المدني',
    value: 'بكالوريوس الدفاع المدني',
  },
  {
    title: 'بكالوريوس العلوم الجوية',
    value: 'بكالوريوس العلوم الجوية',
  },
  {
    title: 'بكالوريوس العلوم البحرية',
    value: 'بكالوريوس العلوم البحرية',
  },
  {
    title: 'بكالوريوس السلامة والصحة المهنية',
    value: 'بكالوريوس السلامة والصحة المهنية',
  },
  {
    title: 'بكالوريوس السياحة والضيافة',
    value: 'بكالوريوس السياحة والضيافة',
  },
  {
    title: 'بكالوريوس إدارة الفنادق',
    value: 'بكالوريوس إدارة الفنادق',
  },
  {
    title: 'بكالوريوس الفيزياء الطبية',
    value: 'بكالوريوس الفيزياء الطبية',
  },
  {
    title: 'بكالوريوس العلوم الفلكية',
    value: 'بكالوريوس العلوم الفلكية',
  },
  {
    title: 'بكالوريوس العلوم الجيوفيزيائية',
    value: 'بكالوريوس العلوم الجيوفيزيائية',
  },
  {
    title: 'بكالوريوس العلوم الجيوكيميائية',
    value: 'بكالوريوس العلوم الجيوكيميائية',
  },
  {
    title: 'بكالوريوس العلوم الجيولوجية البيئية',
    value: 'بكالوريوس العلوم الجيولوجية البيئية',
  },
  {
    title: 'بكالوريوس العلوم الجوية والفضائية',
    value: 'بكالوريوس العلوم الجوية والفضائية',
  },
  {
    title: 'بكالوريوس العلوم النووية',
    value: 'بكالوريوس العلوم النووية',
  },
  {
    title: 'بكالوريوس العلوم الجينية',
    value: 'بكالوريوس العلوم الجينية',
  },
  {
    title: 'بكالوريوس العلوم الوراثية',
    value: 'بكالوريوس العلوم الوراثية',
  },
  {
    title: 'بكالوريوس العلوم العصبية',
    value: 'بكالوريوس العلوم العصبية',
  },
  {
    title: 'بكالوريوس العلوم الحيوانية',
    value: 'بكالوريوس العلوم الحيوانية',
  },
  {
    title: 'بكالوريوس العلوم النباتية',
    value: 'بكالوريوس العلوم النباتية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية الدقيقة',
    value: 'بكالوريوس العلوم الأحيائية الدقيقة',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية الجزيئية',
    value: 'بكالوريوس العلوم الأحيائية الجزيئية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية الخلوية',
    value: 'بكالوريوس العلوم الأحيائية الخلوية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية التطورية',
    value: 'بكالوريوس العلوم الأحيائية التطورية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية السلوكية',
    value: 'بكالوريوس العلوم الأحيائية السلوكية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية البيئية',
    value: 'بكالوريوس العلوم الأحيائية البيئية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية البحرية',
    value: 'بكالوريوس العلوم الأحيائية البحرية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية الزراعية',
    value: 'بكالوريوس العلوم الأحيائية الزراعية',
  },
  {
    title: 'بكالوريوس العلوم الأحيائية الصناعية',
    value: 'بكالوريوس العلوم الأحيائية الصناعية',
  },
  {
    title: 'دبلوم ما بعد الثانوية',
    value: 'دبلوم ما بعد الثانوية',
  },
  {
    title: 'دبلوم الكليات التقنية',
    value: 'دبلوم الكليات التقنية',
  },
  {
    title: 'بكالوريوس',
    value: 'بكالوريوس',
  },
  {
    title: 'بكالوريوس العلوم في الهندسة',
    value: 'بكالوريوس العلوم في الهندسة',
  },
  {
    title: 'بكالوريوس العلوم في الطب',
    value: 'بكالوريوس العلوم في الطب',
  },
  {
    title: 'بكالوريوس العلوم في العلوم التطبيقية',
    value: 'بكالوريوس العلوم في العلوم التطبيقية',
  },
  {
    title: 'بكالوريوس الآداب في الأدب العربي',
    value: 'بكالوريوس الآداب في الأدب العربي',
  },
  {
    title: 'بكالوريوس الآداب في الدراسات الإسلامية',
    value: 'بكالوريوس الآداب في الدراسات الإسلامية',
  },
  {
    title: 'ماجستير',
    value: 'ماجستير',
  },
  {
    title: 'ماجستير في الإدارة العامة',
    value: 'ماجستير في الإدارة العامة',
  },
  {
    title: 'ماجستير في العلوم الهندسية',
    value: 'ماجستير في العلوم الهندسية',
  },
  {
    title: 'ماجستير في العلوم الطبية',
    value: 'ماجستير في العلوم الطبية',
  },
  {
    title: 'ماجستير في العلوم',
    value: 'ماجستير في العلوم',
  },
  {
    title: 'ماجستير في الآداب',
    value: 'ماجستير في الآداب',
  },
  {
    title: 'دكتوراه',
    value: 'دكتوراه',
  },
  {
    title: 'دكتوراه في الهندسة',
    value: 'دكتوراه في الهندسة',
  },
  {
    title: 'دكتوراه في الطب',
    value: 'دكتوراه في الطب',
  },
  {
    title: 'دكتوراه في الصيدلة',
    value: 'دكتوراه في الصيدلة',
  },
  {
    title: 'دكتوراه في العلوم الصحية',
    value: 'دكتوراه في العلوم الصحية',
  },
  {
    title: 'دكتوراه في العلوم',
    value: 'دكتوراه في العلوم',
  },
  {
    title: 'دكتوراه في الآداب',
    value: 'دكتوراه في الآداب',
  },
  {
    title: 'دبلوم عالي',
    value: 'دبلوم عالي',
  },
  {
    title: 'دبلوم عالي في التربية',
    value: 'دبلوم عالي في التربية',
  },
  {
    title: 'شهادة الزمالة في التخصصات الطبية',
    value: 'شهادة الزمالة في التخصصات الطبية',
  },
  {
    title: 'البورد السعودي',
    value: 'البورد السعودي',
  },
  {
    title: 'شهادة مهنية معتمدة',
    value: 'شهادة مهنية معتمدة',
  },
  {
    title: 'الثانوية العامة',
    value: 'الثانوية العامة',
  },
  {
    title: 'دبلوم في الإدارة',
    value: 'دبلوم في الإدارة',
  },
  {
    title: 'دبلوم في تقنية المعلومات',
    value: 'دبلوم في تقنية المعلومات',
  },
  {
    title: 'دبلوم في الهندسة المدنية',
    value: 'دبلوم في الهندسة المدنية',
  },
  {
    title: 'دبلوم في الهندسة الكهربائية',
    value: 'دبلوم في الهندسة الكهربائية',
  },
  {
    title: 'دبلوم في الهندسة الميكانيكية',
    value: 'دبلوم في الهندسة الميكانيكية',
  },
  {
    title: 'دبلوم في العلوم الصحية',
    value: 'دبلوم في العلوم الصحية',
  },
  {
    title: 'دبلوم في الصيدلة',
    value: 'دبلوم في الصيدلة',
  },
  {
    title: 'دبلوم في التمريض',
    value: 'دبلوم في التمريض',
  },
  {
    title: 'دبلوم في المحاسبة',
    value: 'دبلوم في المحاسبة',
  },
  {
    title: 'دبلوم في التسويق',
    value: 'دبلوم في التسويق',
  },
  {
    title: 'دبلوم في السياحة والضيافة',
    value: 'دبلوم في السياحة والضيافة',
  },
  {
    title: 'دبلوم في العلوم الإدارية',
    value: 'دبلوم في العلوم الإدارية',
  },
  {
    title: 'دبلوم في العلوم المالية',
    value: 'دبلوم في العلوم المالية',
  },
  {
    title: 'دبلوم في العلوم البنكية',
    value: 'دبلوم في العلوم البنكية',
  },
  {
    title: 'دبلوم في القانون',
    value: 'دبلوم في القانون',
  },
  {
    title: 'دبلوم في الدراسات الإسلامية',
    value: 'دبلوم في الدراسات الإسلامية',
  },
  {
    title: 'دبلوم في التربية',
    value: 'دبلوم في التربية',
  },
  {
    title: 'دبلوم في اللغة الإنجليزية',
    value: 'دبلوم في اللغة الإنجليزية',
  },
  {
    title: 'دبلوم في اللغة العربية',
    value: 'دبلوم في اللغة العربية',
  },
  {
    title: 'دبلوم في الأدب الإنجليزي',
    value: 'دبلوم في الأدب الإنجليزي',
  },
  {
    title: 'دبلوم في الأدب العربي',
    value: 'دبلوم في الأدب العربي',
  },
  {
    title: 'دبلوم في علم النفس',
    value: 'دبلوم في علم النفس',
  },
  {
    title: 'دبلوم في علم الاجتماع',
    value: 'دبلوم في علم الاجتماع',
  },
  {
    title: 'دبلوم في العلوم السياسية',
    value: 'دبلوم في العلوم السياسية',
  },
  {
    title: 'دبلوم في العلاقات الدولية',
    value: 'دبلوم في العلاقات الدولية',
  },
  {
    title: 'دبلوم في الإعلام',
    value: 'دبلوم في الإعلام',
  },
  {
    title: 'دبلوم في الاتصال الجماهيري',
    value: 'دبلوم في الاتصال الجماهيري',
  },
  {
    title: 'دبلوم في الفنون الجميلة',
    value: 'دبلوم في الفنون الجميلة',
  },
  {
    title: 'دبلوم في التصميم الجرافيكي',
    value: 'دبلوم في التصميم الجرافيكي',
  },
  {
    title: 'دبلوم في التصميم الداخلي',
    value: 'دبلوم في التصميم الداخلي',
  },
  {
    title: 'دبلوم في الهندسة المعمارية',
    value: 'دبلوم في الهندسة المعمارية',
  },
  {
    title: 'دبلوم في العلوم البيئية',
    value: 'دبلوم في العلوم البيئية',
  },
  {
    title: 'دبلوم في الجيولوجيا',
    value: 'دبلوم في الجيولوجيا',
  },
  {
    title: 'دبلوم في البيولوجيا',
    value: 'دبلوم في البيولوجيا',
  },
  {
    title: 'دبلوم في الكيمياء',
    value: 'دبلوم في الكيمياء',
  },
  {
    title: 'دبلوم في الفيزياء',
    value: 'دبلوم في الفيزياء',
  },
  {
    title: 'دبلوم في الرياضيات',
    value: 'دبلوم في الرياضيات',
  },
  {
    title: 'دبلوم في الإحصاء',
    value: 'دبلوم في الإحصاء',
  },
  {
    title: 'دبلوم في العلوم الزراعية',
    value: 'دبلوم في العلوم الزراعية',
  },
  {
    title: 'دبلوم في الهندسة الزراعية',
    value: 'دبلوم في الهندسة الزراعية',
  },
  {
    title: 'دبلوم في الهندسة البترولية',
    value: 'دبلوم في الهندسة البترولية',
  },
  {
    title: 'دبلوم في هندسة الغاز',
    value: 'دبلوم في هندسة الغاز',
  },
  {
    title: 'دبلوم في الهندسة الكيميائية',
    value: 'دبلوم في الهندسة الكيميائية',
  },
  {
    title: 'دبلوم في الهندسة البيئية',
    value: 'دبلوم في الهندسة البيئية',
  },
  {
    title: 'دبلوم في الهندسة الجيوتقنية',
    value: 'دبلوم في الهندسة الجيوتقنية',
  },
  {
    title: 'دبلوم في الهندسة الصناعية',
    value: 'دبلوم في الهندسة الصناعية',
  },
  {
    title: 'دبلوم في هندسة النظم',
    value: 'دبلوم في هندسة النظم',
  },
  {
    title: 'دبلوم في الهندسة البحرية',
    value: 'دبلوم في الهندسة البحرية',
  },
  {
    title: 'دبلوم في الطيران',
    value: 'دبلوم في الطيران',
  },
  {
    title: 'دبلوم في هندسة الطيران',
    value: 'دبلوم في هندسة الطيران',
  },
  {
    title: 'دبلوم في إدارة الطيران',
    value: 'دبلوم في إدارة الطيران',
  },
  {
    title: 'دبلوم في السلامة والصحة المهنية',
    value: 'دبلوم في السلامة والصحة المهنية',
  },
  {
    title: 'دبلوم في الأمن السيبراني',
    value: 'دبلوم في الأمن السيبراني',
  },
  {
    title: 'دبلوم في شبكات الكمبيوتر',
    value: 'دبلوم في شبكات الكمبيوتر',
  },
  {
    title: 'دبلوم في تطوير البرمجيات',
    value: 'دبلوم في تطوير البرمجيات',
  },
  {
    title: 'دبلوم في نظم المعلومات الجغرافية',
    value: 'دبلوم في نظم المعلومات الجغرافية',
  },
  {
    title: 'دبلوم في الذكاء الاصطناعي',
    value: 'دبلوم في الذكاء الاصطناعي',
  },
  {
    title: 'دبلوم في علم البيانات',
    value: 'دبلوم في علم البيانات',
  },
  {
    title: 'دبلوم في التعليم الإلكتروني',
    value: 'دبلوم في التعليم الإلكتروني',
  },
  {
    title: 'دبلوم في التعليم المبكر',
    value: 'دبلوم في التعليم المبكر',
  },
  {
    title: 'دبلوم في تعليم اللغة الإنجليزية كلغة ثانية',
    value: 'دبلوم في تعليم اللغة الإنجليزية كلغة ثانية',
  },
  {
    title: 'دبلوم في إدارة الموارد البشرية',
    value: 'دبلوم في إدارة الموارد البشرية',
  },
  {
    title: 'دبلوم في إدارة سلسلة التوريد',
    value: 'دبلوم في إدارة سلسلة التوريد',
  },
  {
    title: 'دبلوم في إدارة المشاريع',
    value: 'دبلوم في إدارة المشاريع',
  },
  {
    title: 'دبلوم في الجودة الشاملة',
    value: 'دبلوم في الجودة الشاملة',
  },
  {
    title: 'دبلوم في اللوجستيات',
    value: 'دبلوم في اللوجستيات',
  },
  {
    title: 'دبلوم في التجارة الإلكترونية',
    value: 'دبلوم في التجارة الإلكترونية',
  },
  {
    title: 'دبلوم في التسويق الرقمي',
    value: 'دبلوم في التسويق الرقمي',
  },
  {
    title: 'دبلوم في ريادة الأعمال',
    value: 'دبلوم في ريادة الأعمال',
  },
  {
    title: 'دبلوم في الاقتصاد الإسلامي',
    value: 'دبلوم في الاقتصاد الإسلامي',
  },
  {
    title: 'دبلوم في المالية الإسلامية',
    value: 'دبلوم في المالية الإسلامية',
  },
  {
    title: 'دبلوم في التأمين الإسلامي',
    value: 'دبلوم في التأمين الإسلامي',
  },
  {
    title: 'دبلوم في الفقه الإسلامي',
    value: 'دبلوم في الفقه الإسلامي',
  },
  {
    title: 'دبلوم في أصول الدين',
    value: 'دبلوم في أصول الدين',
  },
  {
    title: 'دبلوم في الحديث وعلومه',
    value: 'دبلوم في الحديث وعلومه',
  },
  {
    title: 'دبلوم في التفسير وعلوم القرآن',
    value: 'دبلوم في التفسير وعلوم القرآن',
  },
  {
    title: 'دبلوم في الدعوة وأصول الدين',
    value: 'دبلوم في الدعوة وأصول الدين',
  },
  {
    title: 'دبلوم في اللغة الفرنسية',
    value: 'دبلوم في اللغة الفرنسية',
  },
  {
    title: 'دبلوم في اللغة الألمانية',
    value: 'دبلوم في اللغة الألمانية',
  },
  {
    title: 'دبلوم في اللغة الإسبانية',
    value: 'دبلوم في اللغة الإسبانية',
  },
  {
    title: 'دبلوم في اللغة الإيطالية',
    value: 'دبلوم في اللغة الإيطالية',
  },
  {
    title: 'دبلوم في اللغة اليابانية',
    value: 'دبلوم في اللغة اليابانية',
  },
  {
    title: 'دبلوم في اللغة الصينية',
    value: 'دبلوم في اللغة الصينية',
  },
  {
    title: 'دبلوم في اللغة الروسية',
    value: 'دبلوم في اللغة الروسية',
  },
  {
    title: 'دبلوم في اللغة الفارسية',
    value: 'دبلوم في اللغة الفارسية',
  },
  {
    title: 'دبلوم في اللغة التركية',
    value: 'دبلوم في اللغة التركية',
  },
  {
    title: 'دبلوم في اللغة الكورية',
    value: 'دبلوم في اللغة الكورية',
  },
  {
    title: 'دبلوم في اللغة الهندية',
    value: 'دبلوم في اللغة الهندية',
  },
  {
    title: 'دبلوم في اللغة الأردية',
    value: 'دبلوم في اللغة الأردية',
  },
  {
    title: 'دبلوم في اللغة البرتغالية',
    value: 'دبلوم في اللغة البرتغالية',
  },
  {
    title: 'دبلوم في الترجمة الفورية',
    value: 'دبلوم في الترجمة الفورية',
  },
  {
    title: 'دبلوم في الترجمة التحريرية',
    value: 'دبلوم في الترجمة التحريرية',
  },
  {
    title: 'دبلوم في الترجمة القانونية',
    value: 'دبلوم في الترجمة القانونية',
  },
  {
    title: 'دبلوم في الترجمة الطبية',
    value: 'دبلوم في الترجمة الطبية',
  },
  {
    title: 'دبلوم في الترجمة التقنية',
    value: 'دبلوم في الترجمة التقنية',
  },
  {
    title: 'دبلوم في الصحافة',
    value: 'دبلوم في الصحافة',
  },
  {
    title: 'دبلوم في الإذاعة والتلفزيون',
    value: 'دبلوم في الإذاعة والتلفزيون',
  },
  {
    title: 'دبلوم في الإنتاج الإعلامي',
    value: 'دبلوم في الإنتاج الإعلامي',
  },
  {
    title: 'دبلوم في العلاقات العامة',
    value: 'دبلوم في العلاقات العامة',
  },
  {
    title: 'دبلوم في الإعلان',
    value: 'دبلوم في الإعلان',
  },
  {
    title: 'دبلوم في الفوتوغرافيا',
    value: 'دبلوم في الفوتوغرافيا',
  },
  {
    title: 'دبلوم في السينما',
    value: 'دبلوم في السينما',
  },
  {
    title: 'دبلوم في المسرح',
    value: 'دبلوم في المسرح',
  },
  {
    title: 'دبلوم في الموسيقى',
    value: 'دبلوم في الموسيقى',
  },
  {
    title: 'دبلوم في الرسم والتصوير',
    value: 'دبلوم في الرسم والتصوير',
  },
  {
    title: 'دبلوم في النحت',
    value: 'دبلوم في النحت',
  },
  {
    title: 'دبلوم في الخزف',
    value: 'دبلوم في الخزف',
  },
  {
    title: 'دبلوم في التصوير الضوئي',
    value: 'دبلوم في التصوير الضوئي',
  },
  {
    title: 'دبلوم في الأزياء والموضة',
    value: 'دبلوم في الأزياء والموضة',
  },
  {
    title: 'دبلوم في تصميم الأزياء',
    value: 'دبلوم في تصميم الأزياء',
  },
  {
    title: 'دبلوم في تصميم الأحذية والحقائب',
    value: 'دبلوم في تصميم الأحذية والحقائب',
  },
  {
    title: 'دبلوم في تصميم المجوهرات',
    value: 'دبلوم في تصميم المجوهرات',
  },
  {
    title: 'دبلوم في العمارة الداخلية',
    value: 'دبلوم في العمارة الداخلية',
  },
  {
    title: 'دبلوم في التخطيط العمراني',
    value: 'دبلوم في التخطيط العمراني',
  },
  {
    title: 'دبلوم في التصميم الحضري',
    value: 'دبلوم في التصميم الحضري',
  },
  {
    title: 'دبلوم في البستنة والمناظر الطبيعية',
    value: 'دبلوم في البستنة والمناظر الطبيعية',
  },
  {
    title: 'دبلوم في الزراعة العضوية',
    value: 'دبلوم في الزراعة العضوية',
  },
  {
    title: 'دبلوم في الإنتاج الحيواني',
    value: 'دبلوم في الإنتاج الحيواني',
  },
  {
    title: 'دبلوم في الإنتاج النباتي',
    value: 'دبلوم في الإنتاج النباتي',
  },
  {
    title: 'دبلوم في الهندسة الوراثية',
    value: 'دبلوم في الهندسة الوراثية',
  },
  {
    title: 'دبلوم في التكنولوجيا الحيوية',
    value: 'دبلوم في التكنولوجيا الحيوية',
  },
  {
    title: 'دبلوم في علم الأحياء الدقيقة',
    value: 'دبلوم في علم الأحياء الدقيقة',
  },
  {
    title: 'دبلوم في الكيمياء الحيوية',
    value: 'دبلوم في الكيمياء الحيوية',
  },
  {
    title: 'دبلوم في الفيزياء الطبية',
    value: 'دبلوم في الفيزياء الطبية',
  },
  {
    title: 'دبلوم في الرياضيات التطبيقية',
    value: 'دبلوم في الرياضيات التطبيقية',
  },
  {
    title: 'دبلوم في الإحصاء التطبيقي',
    value: 'دبلوم في الإحصاء التطبيقي',
  },
  {
    title: 'دبلوم في العلوم الجيوفيزيائية',
    value: 'دبلوم في العلوم الجيوفيزيائية',
  },
  {
    title: 'دبلوم في علم المعادن',
    value: 'دبلوم في علم المعادن',
  },
  {
    title: 'دبلوم في علم الأرصاد الجوية',
    value: 'دبلوم في علم الأرصاد الجوية',
  },
  {
    title: 'دبلوم في علوم البحار',
    value: 'دبلوم في علوم البحار',
  },
  {
    title: 'دبلوم في الإدارة البيئية',
    value: 'دبلوم في الإدارة البيئية',
  },
  {
    title: 'دبلوم في السلامة البيئية',
    value: 'دبلوم في السلامة البيئية',
  },
  {
    title: 'دبلوم في الهندسة الجيولوجية',
    value: 'دبلوم في الهندسة الجيولوجية',
  },
  {
    title: 'دبلوم في الهندسة النووية',
    value: 'دبلوم في الهندسة النووية',
  },
  {
    title: 'دبلوم في الهندسة الصوتية',
    value: 'دبلوم في الهندسة الصوتية',
  },
  {
    title: 'دبلوم في هندسة الاتصالات',
    value: 'دبلوم في هندسة الاتصالات',
  },
  {
    title: 'دبلوم في هندسة البرمجيات',
    value: 'دبلوم في هندسة البرمجيات',
  },
  {
    title: 'دبلوم في هندسة الحاسب الآلي',
    value: 'دبلوم في هندسة الحاسب الآلي',
  },
  {
    title: 'دبلوم في الروبوتات',
    value: 'دبلوم في الروبوتات',
  },
  {
    title: 'دبلوم في الأمن والحماية',
    value: 'دبلوم في الأمن والحماية',
  },
  {
    title: 'دبلوم في مكافحة الحرائق',
    value: 'دبلوم في مكافحة الحرائق',
  },
  {
    title: 'دبلوم في الإسعافات الأولية',
    value: 'دبلوم في الإسعافات الأولية',
  },
  {
    title: 'دبلوم في الرعاية الصحية المنزلية',
    value: 'دبلوم في الرعاية الصحية المنزلية',
  },
  {
    title: 'دبلوم في الصحة العامة',
    value: 'دبلوم في الصحة العامة',
  },
  {
    title: 'دبلوم في الصحة والسلامة المهنية',
    value: 'دبلوم في الصحة والسلامة المهنية',
  },
  {
    title: 'دبلوم في الإدارة الصحية',
    value: 'دبلوم في الإدارة الصحية',
  },
  {
    title: 'دبلوم في التغذية العلاجية',
    value: 'دبلوم في التغذية العلاجية',
  },
  {
    title: 'دبلوم في العلاج الطبيعي',
    value: 'دبلوم في العلاج الطبيعي',
  },
  {
    title: 'دبلوم في العلاج الوظيفي',
    value: 'دبلوم في العلاج الوظيفي',
  },
  {
    title: 'دبلوم في العلاج النفسي',
    value: 'دبلوم في العلاج النفسي',
  },
  {
    title: 'دبلوم في العلاج بالفن',
    value: 'دبلوم في العلاج بالفن',
  },
  {
    title: 'دبلوم في العلاج بالموسيقى',
    value: 'دبلوم في العلاج بالموسيقى',
  },
  {
    title: 'دبلوم في العلاج بالرقص',
    value: 'دبلوم في العلاج بالرقص',
  },
  {
    title: 'دبلوم في العلاج بالحيوانات',
    value: 'دبلوم في العلاج بالحيوانات',
  },
  {
    title: 'دبلوم في علم النفس الإكلينيكي',
    value: 'دبلوم في علم النفس الإكلينيكي',
  },
  {
    title: 'دبلوم في علم النفس التربوي',
    value: 'دبلوم في علم النفس التربوي',
  },
  {
    title: 'دبلوم في علم النفس الجنائي',
    value: 'دبلوم في علم النفس الجنائي',
  },
  {
    title: 'دبلوم في علم النفس الرياضي',
    value: 'دبلوم في علم النفس الرياضي',
  },
  {
    title: 'دبلوم في علم النفس الاجتماعي',
    value: 'دبلوم في علم النفس الاجتماعي',
  },
  {
    title: 'دبلوم في علم النفس العملي',
    value: 'دبلوم في علم النفس العملي',
  },
  {
    title: 'دبلوم في علم الاجتماع الطبي',
    value: 'دبلوم في علم الاجتماع الطبي',
  },
  {
    title: 'دبلوم في علم الاجتماع الصناعي',
    value: 'دبلوم في علم الاجتماع الصناعي',
  },
  {
    title: 'دبلوم في علم الاجتماع الحضري',
    value: 'دبلوم في علم الاجتماع الحضري',
  },
  {
    title: 'دبلوم في العلوم السياسية والإدارة العامة',
    value: 'دبلوم في العلوم السياسية والإدارة العامة',
  },
  {
    title: 'دبلوم في السياسة الدولية',
    value: 'دبلوم في السياسة الدولية',
  },
  {
    title: 'دبلوم في الدبلوماسية والعلاقات الدولية',
    value: 'دبلوم في الدبلوماسية والعلاقات الدولية',
  },
  {
    title: 'دبلوم في الحقوق الدولية',
    value: 'دبلوم في الحقوق الدولية',
  },
  {
    title: 'دبلوم في الحقوق الإنسانية',
    value: 'دبلوم في الحقوق الإنسانية',
  },
  {
    title: 'دبلوم في القانون التجاري',
    value: 'دبلوم في القانون التجاري',
  },
  {
    title: 'دبلوم في القانون الجنائي',
    value: 'دبلوم في القانون الجنائي',
  },
  {
    title: 'دبلوم في القانون المدني',
    value: 'دبلوم في القانون المدني',
  },
  {
    title: 'دبلوم في القانون الدستوري',
    value: 'دبلوم في القانون الدستوري',
  },
  {
    title: 'دبلوم في القانون الإداري',
    value: 'دبلوم في القانون الإداري',
  },
  {
    title: 'دبلوم في القانون البحري',
    value: 'دبلوم في القانون البحري',
  },
  {
    title: 'دبلوم في القانون الجوي',
    value: 'دبلوم في القانون الجوي',
  },
  {
    title: 'دبلوم في القانون البيئي',
    value: 'دبلوم في القانون البيئي',
  },
  {
    title: 'دبلوم في القانون العقاري',
    value: 'دبلوم في القانون العقاري',
  },
  {
    title: 'دبلوم في القانون الضريبي',
    value: 'دبلوم في القانون الضريبي',
  },
  {
    title: 'دبلوم في القانون العمل',
    value: 'دبلوم في القانون العمل',
  },
  {
    title: 'دبلوم في المحاسبة الإدارية',
    value: 'دبلوم في المحاسبة الإدارية',
  },
  {
    title: 'دبلوم في المحاسبة المالية',
    value: 'دبلوم في المحاسبة المالية',
  },
  {
    title: 'دبلوم في المحاسبة الضريبية',
    value: 'دبلوم في المحاسبة الضريبية',
  },
  {
    title: 'دبلوم في المحاسبة الحكومية',
    value: 'دبلوم في المحاسبة الحكومية',
  },
  {
    title: 'دبلوم في المراجعة والتدقيق',
    value: 'دبلوم في المراجعة والتدقيق',
  },
  {
    title: 'دبلوم في التحليل المالي',
    value: 'دبلوم في التحليل المالي',
  },
  {
    title: 'دبلوم في التخطيط المالي',
    value: 'دبلوم في التخطيط المالي',
  },
  {
    title: 'دبلوم في الإدارة المالية',
    value: 'دبلوم في الإدارة المالية',
  },
  {
    title: 'دبلوم في الاستثمار والتمويل',
    value: 'دبلوم في الاستثمار والتمويل',
  },
  {
    title: 'دبلوم في الأسواق المالية',
    value: 'دبلوم في الأسواق المالية',
  },
  {
    title: 'دبلوم في تداول الأسهم',
    value: 'دبلوم في تداول الأسهم',
  },
  {
    title: 'دبلوم في العقود الآجلة والخيارات',
    value: 'دبلوم في العقود الآجلة والخيارات',
  },
  {
    title: 'دبلوم في العملات والفوركس',
    value: 'دبلوم في العملات والفوركس',
  },
  {
    title: 'دبلوم في الذهب والمعادن الثمينة',
    value: 'دبلوم في الذهب والمعادن الثمينة',
  },
  {
    title: 'دبلوم في النفط والغاز',
    value: 'دبلوم في النفط والغاز',
  },
  {
    title: 'دبلوم في الطاقة المتجددة',
    value: 'دبلوم في الطاقة المتجددة',
  },
  {
    title: 'دبلوم في الطاقة النووية',
    value: 'دبلوم في الطاقة النووية',
  },
  {
    title: 'دبلوم في إدارة الطاقة',
    value: 'دبلوم في إدارة الطاقة',
  },
  {
    title: 'دبلوم في الهندسة الطاقوية',
    value: 'دبلوم في الهندسة الطاقوية',
  },
  {
    title: 'دبلوم في الاستدامة والتنمية البيئية',
    value: 'دبلوم في الاستدامة والتنمية البيئية',
  },
  {
    title: 'دبلوم الهندسة المدنية',
    value: 'دبلوم الهندسة المدنية',
  },
  {
    title: 'دبلوم الهندسة الكهربائية',
    value: 'دبلوم الهندسة الكهربائية',
  },
  {
    title: 'دبلوم الهندسة الميكانيكية',
    value: 'دبلوم الهندسة الميكانيكية',
  },
  {
    title: 'دبلوم تقنية المعلومات',
    value: 'دبلوم تقنية المعلومات',
  },
  {
    title: 'دبلوم علوم الحاسب',
    value: 'دبلوم علوم الحاسب',
  },
  {
    title: 'دبلوم الإدارة الصحية',
    value: 'دبلوم الإدارة الصحية',
  },
  {
    title: 'دبلوم الصيدلة',
    value: 'دبلوم الصيدلة',
  },
  {
    title: 'دبلوم التمريض',
    value: 'دبلوم التمريض',
  },
  {
    title: 'دبلوم المحاسبة',
    value: 'دبلوم المحاسبة',
  },
  {
    title: 'دبلوم إدارة الأعمال',
    value: 'دبلوم إدارة الأعمال',
  },
  {
    title: 'دبلوم التسويق',
    value: 'دبلوم التسويق',
  },
  {
    title: 'دبلوم الموارد البشرية',
    value: 'دبلوم الموارد البشرية',
  },
  {
    title: 'دبلوم العلاقات العامة',
    value: 'دبلوم العلاقات العامة',
  },
  {
    title: 'دبلوم الإعلام الرقمي',
    value: 'دبلوم الإعلام الرقمي',
  },
  {
    title: 'دبلوم التصميم الجرافيكي',
    value: 'دبلوم التصميم الجرافيكي',
  },
  {
    title: 'دبلوم العمارة',
    value: 'دبلوم العمارة',
  },
  {
    title: 'دبلوم التصميم الداخلي',
    value: 'دبلوم التصميم الداخلي',
  },
  {
    title: 'دبلوم السياحة والفندقة',
    value: 'دبلوم السياحة والفندقة',
  },
  {
    title: 'دبلوم الطيران',
    value: 'دبلوم الطيران',
  },
  {
    title: 'دبلوم اللوجستيات',
    value: 'دبلوم اللوجستيات',
  },
  {
    title: 'دبلوم الأمن السيبراني',
    value: 'دبلوم الأمن السيبراني',
  },
  {
    title: 'دبلوم البرمجة',
    value: 'دبلوم البرمجة',
  },
  {
    title: 'دبلوم الشبكات',
    value: 'دبلوم الشبكات',
  },
  {
    title: 'دبلوم الأمن والسلامة',
    value: 'دبلوم الأمن والسلامة',
  },
  {
    title: 'دبلوم الجودة',
    value: 'دبلوم الجودة',
  },
  {
    title: 'بكالوريوس في الهندسة المدنية',
    value: 'بكالوريوس في الهندسة المدنية',
  },
  {
    title: 'بكالوريوس في الهندسة الكهربائية',
    value: 'بكالوريوس في الهندسة الكهربائية',
  },
  {
    title: 'بكالوريوس في الهندسة الميكانيكية',
    value: 'بكالوريوس في الهندسة الميكانيكية',
  },
  {
    title: 'بكالوريوس في تقنية المعلومات',
    value: 'بكالوريوس في تقنية المعلومات',
  },
  {
    title: 'بكالوريوس في علوم الحاسب',
    value: 'بكالوريوس في علوم الحاسب',
  },
  {
    title: 'بكالوريوس في الإدارة الصحية',
    value: 'بكالوريوس في الإدارة الصحية',
  },
  {
    title: 'بكالوريوس في الصيدلة',
    value: 'بكالوريوس في الصيدلة',
  },
  {
    title: 'بكالوريوس في التمريض',
    value: 'بكالوريوس في التمريض',
  },
  {
    title: 'بكالوريوس في المحاسبة',
    value: 'بكالوريوس في المحاسبة',
  },
  {
    title: 'بكالوريوس في إدارة الأعمال',
    value: 'بكالوريوس في إدارة الأعمال',
  },
  {
    title: 'بكالوريوس في التسويق',
    value: 'بكالوريوس في التسويق',
  },
  {
    title: 'بكالوريوس في الموارد البشرية',
    value: 'بكالوريوس في الموارد البشرية',
  },
  {
    title: 'بكالوريوس في العلاقات العامة',
    value: 'بكالوريوس في العلاقات العامة',
  },
  {
    title: 'بكالوريوس في الإعلام الرقمي',
    value: 'بكالوريوس في الإعلام الرقمي',
  },
  {
    title: 'بكالوريوس في التصميم الجرافيكي',
    value: 'بكالوريوس في التصميم الجرافيكي',
  },
  {
    title: 'بكالوريوس في العمارة',
    value: 'بكالوريوس في العمارة',
  },
  {
    title: 'بكالوريوس في التصميم الداخلي',
    value: 'بكالوريوس في التصميم الداخلي',
  },
  {
    title: 'بكالوريوس في السياحة والفندقة',
    value: 'بكالوريوس في السياحة والفندقة',
  },
  {
    title: 'بكالوريوس في الطيران',
    value: 'بكالوريوس في الطيران',
  },
  {
    title: 'بكالوريوس في اللوجستيات',
    value: 'بكالوريوس في اللوجستيات',
  },
  {
    title: 'ماجستير في الهندسة المدنية',
    value: 'ماجستير في الهندسة المدنية',
  },
  {
    title: 'ماجستير في الهندسة الكهربائية',
    value: 'ماجستير في الهندسة الكهربائية',
  },
  {
    title: 'ماجستير في الهندسة الميكانيكية',
    value: 'ماجستير في الهندسة الميكانيكية',
  },
  {
    title: 'ماجستير في تقنية المعلومات',
    value: 'ماجستير في تقنية المعلومات',
  },
  {
    title: 'ماجستير في علوم الحاسب',
    value: 'ماجستير في علوم الحاسب',
  },
  {
    title: 'ماجستير في الإدارة الصحية',
    value: 'ماجستير في الإدارة الصحية',
  },
  {
    title: 'ماجستير في الصيدلة',
    value: 'ماجستير في الصيدلة',
  },
  {
    title: 'ماجستير في التمريض',
    value: 'ماجستير في التمريض',
  },
  {
    title: 'ماجستير في المحاسبة',
    value: 'ماجستير في المحاسبة',
  },
  {
    title: 'ماجستير في إدارة الأعمال',
    value: 'ماجستير في إدارة الأعمال',
  },
  {
    title: 'ماجستير في التسويق',
    value: 'ماجستير في التسويق',
  },
  {
    title: 'ماجستير في الموارد البشرية',
    value: 'ماجستير في الموارد البشرية',
  },
  {
    title: 'ماجستير في العلاقات العامة',
    value: 'ماجستير في العلاقات العامة',
  },
  {
    title: 'ماجستير في الإعلام الرقمي',
    value: 'ماجستير في الإعلام الرقمي',
  },
  {
    title: 'ماجستير في التصميم الجرافيكي',
    value: 'ماجستير في التصميم الجرافيكي',
  },
  {
    title: 'دكتوراه في الهندسة المدنية',
    value: 'دكتوراه في الهندسة المدنية',
  },
  {
    title: 'دكتوراه في الهندسة الكهربائية',
    value: 'دكتوراه في الهندسة الكهربائية',
  },
  {
    title: 'دكتوراه في الهندسة الميكانيكية',
    value: 'دكتوراه في الهندسة الميكانيكية',
  },
  {
    title: 'دكتوراه في تقنية المعلومات',
    value: 'دكتوراه في تقنية المعلومات',
  },
  {
    title: 'دكتوراه في علوم الحاسب',
    value: 'دكتوراه في علوم الحاسب',
  },
  {
    title: 'دكتوراه في الإدارة الصحية',
    value: 'دكتوراه في الإدارة الصحية',
  },
  {
    title: 'دكتوراه في التمريض',
    value: 'دكتوراه في التمريض',
  },
  {
    title: 'دكتوراه في المحاسبة',
    value: 'دكتوراه في المحاسبة',
  },
  {
    title: 'دكتوراه في إدارة الأعمال',
    value: 'دكتوراه في إدارة الأعمال',
  },
  {
    title: 'شهادة محترف في إدارة المشاريع',
    value: 'شهادة محترف في إدارة المشاريع',
  },
  {
    title: 'شهادة محلل الأعمال المعتمد',
    value: 'شهادة محلل الأعمال المعتمد',
  },
  {
    title: 'شهادة محترف الأمن السيبراني',
    value: 'شهادة محترف الأمن السيبراني',
  },
  {
    title: 'شهادة مطور الويب المعتمد',
    value: 'شهادة مطور الويب المعتمد',
  },
  {
    title: 'شهادة محترف التسويق الرقمي',
    value: 'شهادة محترف التسويق الرقمي',
  },
  {
    title: 'شهادة محترف إدارة الموارد البشرية',
    value: 'شهادة محترف إدارة الموارد البشرية',
  },
  {
    title: 'شهادة محترف السلامة والصحة المهنية',
    value: 'شهادة محترف السلامة والصحة المهنية',
  },
  {
    title: 'شهادة محترف الجودة المعتمد',
    value: 'شهادة محترف الجودة المعتمد',
  },
  {
    title: 'شهادة محترف التصميم الجرافيكي',
    value: 'شهادة محترف التصميم الجرافيكي',
  },
  {
    title: 'شهادة محترف اللوجستيات المعتمد',
    value: 'شهادة محترف اللوجستيات المعتمد',
  },
  {
    title: 'برنامج تدريبي متقدم في الذكاء الاصطناعي',
    value: 'برنامج تدريبي متقدم في الذكاء الاصطناعي',
  },
  {
    title: 'برنامج تدريبي متقدم في تحليل البيانات',
    value: 'برنامج تدريبي متقدم في تحليل البيانات',
  },
  {
    title: 'برنامج تدريبي متقدم في تطوير البرمجيات',
    value: 'برنامج تدريبي متقدم في تطوير البرمجيات',
  },
  {
    title: 'برنامج تدريبي متقدم في الهندسة البيوطبية',
    value: 'برنامج تدريبي متقدم في الهندسة البيوطبية',
  },
  {
    title: 'برنامج تدريبي متقدم في الطاقة المتجددة',
    value: 'برنامج تدريبي متقدم في الطاقة المتجددة',
  },
  {
    title: 'برنامج تدريبي متقدم في إدارة سلسلة التوريد',
    value: 'برنامج تدريبي متقدم في إدارة سلسلة التوريد',
  },
  {
    title: 'برنامج تدريبي متقدم في التسويق الإلكتروني',
    value: 'برنامج تدريبي متقدم في التسويق الإلكتروني',
  },
  {
    title: 'برنامج تدريبي متقدم في العلاقات العامة والإعلام',
    value: 'برنامج تدريبي متقدم في العلاقات العامة والإعلام',
  },
  {
    title: 'برنامج تدريبي متقدم في العلوم المالية والمصرفية',
    value: 'برنامج تدريبي متقدم في العلوم المالية والمصرفية',
  },
  {
    title: 'برنامج تدريبي متقدم في العلوم الصحية',
    value: 'برنامج تدريبي متقدم في العلوم الصحية',
  },
  {
    title: 'بكالوريس طب وجراحة الفم والاسنان',
    value: 'بكالوريس طب وجراحة الفم والاسنان',
  },
  {
    title: 'ورشة عمل بعنوان (الأمن السيبراني للشركات الناشئة)',
    value: 'ورشة عمل بعنوان (الأمن السيبراني للشركات الناشئة)',
  },
  {
    title: 'ورشة عمل (تشجيع القلب)',
    value: 'ورشة عمل (تشجيع القلب)',
  },
  {
    title: 'ورشة عمل',
    value: 'ورشة عمل',
  },
  {
    title: 'ورشة تدريبية بعنوان (الإيجابية مفتاح النجاح)',
    value: 'ورشة تدريبية بعنوان (الإيجابية مفتاح النجاح)',
  },
  {
    title: 'ورش العمل',
    value: 'ورش العمل',
  },
  {
    title: 'هندسة طبية حيوية',
    value: 'هندسة طبية حيوية',
  },
  {
    title: 'معهد فني تجاري قسم ادارة اعمالل',
    value: 'معهد فني تجاري قسم ادارة اعمالل',
  },
  {
    title: 'مدرسة الثانوية الثالثة بخميس مشيط',
    value: 'مدرسة الثانوية الثالثة بخميس مشيط',
  },
  {
    title: 'مدربة للملاكمة التايلندية',
    value: 'مدربة للملاكمة التايلندية',
  },
  {
    title: 'مدربة كيك بو كسينغ و مواي تاي و الكاوان معتمدة',
    value: 'مدربة كيك بو كسينغ و مواي تاي و الكاوان معتمدة',
  },
  {
    title: 'مدربة شخصية',
    value: 'مدربة شخصية',
  },
  {
    title: 'محاضرة بعنوان (مهارات الإتصال و الإقناع في مواجهة الانحرافات الفكرية)',
    value: 'محاضرة بعنوان (مهارات الإتصال و الإقناع في مواجهة الانحرافات الفكرية)',
  },
  {
    title: 'محاضرة بعنوان (رفع مستوى الحس الأمني)',
    value: 'محاضرة بعنوان (رفع مستوى الحس الأمني)',
  },
  {
    title: 'محاسبة',
    value: 'محاسبة',
  },
  {
    title: 'ماجستير الهندسة الكهربائية',
    value: 'ماجستير الهندسة الكهربائية',
  },
  {
    title: 'ليسانس اداب - قسم جغرافيا - شعبة مساحة وخرائط',
    value: 'ليسانس اداب - قسم جغرافيا - شعبة مساحة وخرائط',
  },
  {
    title: 'لغة انجليزية',
    value: 'لغة انجليزية',
  },
  {
    title: 'شهاده ثانويه',
    value: 'شهاده ثانويه',
  },
  {
    title: 'شهاده',
    value: 'شهاده',
  },
  {
    title: 'شهادة متوسطة',
    value: 'شهادة متوسطة',
  },
  {
    title: 'شهادة في المكتبيات',
    value: 'شهادة في المكتبيات',
  },
  {
    title: 'شهادة في الفندقة',
    value: 'شهادة في الفندقة',
  },
  {
    title: 'شهادة بكالوريوس التربية في الدراسات الاسلامية',
    value: 'شهادة بكالوريوس التربية في الدراسات الاسلامية',
  },
  {
    title: 'شهادة برنامج مسك للاعداد الوظيفي.',
    value: 'شهادة برنامج مسك للاعداد الوظيفي.',
  },
  {
    title: 'شهادة برنامج تدريبي في لحام الأنابيب من قسم : الميكانيكا بمعدل تراكمي ٥ من ٥ وبنسبة ١٠٠ ٪ وبتقدير ممتاز',
    value: 'شهادة برنامج تدريبي في لحام الأنابيب من قسم : الميكانيكا بمعدل تراكمي ٥ من ٥ وبنسبة ١٠٠ ٪ وبتقدير ممتاز',
  },
  {
    title: 'شهادة انهاء المرحله المتوسطه',
    value: 'شهادة انهاء المرحله المتوسطه',
  },
  {
    title: 'شهادة المرحلة المتوسطة',
    value: 'شهادة المرحلة المتوسطة',
  },
  {
    title: 'شهادة الدراسة المتوسطة',
    value: 'شهادة الدراسة المتوسطة',
  },
  {
    title: 'شهادة الدراسة',
    value: 'شهادة الدراسة',
  },
  {
    title: 'شهادة التطبيقات المكتبيةعلى الحاسب الآلي والسكرتارية',
    value: 'شهادة التطبيقات المكتبيةعلى الحاسب الآلي والسكرتارية',
  },
  {
    title: 'شهادة البكالوريس في تخصص الصحة العامة',
    value: 'شهادة البكالوريس في تخصص الصحة العامة',
  },
  {
    title: 'شاهدة المرحلة الابتدائية',
    value: 'شاهدة المرحلة الابتدائية',
  },
  {
    title: 'ذو ٱ',
    value: 'ذو ٱ',
  },
  {
    title: 'دوره فرد اساس',
    value: 'دوره فرد اساس',
  },
  {
    title: 'دوره أعمال القوات',
    value: 'دوره أعمال القوات',
  },
  {
    title: 'دورة فن المقابلات الشخصية و إعداد السيرة الذاتية',
    value: 'دورة فن المقابلات الشخصية و إعداد السيرة الذاتية',
  },
  {
    title: 'دورة عن برنامج microsoft (sway)',
    value: 'دورة عن برنامج microsoft (sway)',
  },
  {
    title: 'دورة تطويرية في برنامج الموارد البشرية الحديثة',
    value: 'دورة تطويرية في برنامج الموارد البشرية الحديثة',
  },
  {
    title: 'دورة تطويرية',
    value: 'دورة تطويرية',
  },
  {
    title: 'دورة تدريبية بعنوان (تقويم البرامج التدريبية)',
    value: 'دورة تدريبية بعنوان (تقويم البرامج التدريبية)',
  },
  {
    title: 'دورة تدريبية بعنوان (تحديد الاحتياجات التدريبية)',
    value: 'دورة تدريبية بعنوان (تحديد الاحتياجات التدريبية)',
  },
  {
    title: 'دورة تدريبية بعنوان (الإتصالات الإدارية)',
    value: 'دورة تدريبية بعنوان (الإتصالات الإدارية)',
  },
  {
    title: 'دورة تدريبية',
    value: 'دورة تدريبية',
  },
  {
    title: 'دورة تأهيلية',
    value: 'دورة تأهيلية',
  },
  {
    title: 'دبلومه في اداره الاعمال',
    value: 'دبلومه في اداره الاعمال',
  },
  {
    title: 'دبلوم هندسه معماريه',
    value: 'دبلوم هندسه معماريه',
  },
  {
    title: 'دبلوم نجاره',
    value: 'دبلوم نجاره',
  },
  {
    title: 'دبلوم محاسبه',
    value: 'دبلوم محاسبه',
  },
  {
    title: 'دبلوم محاسبة ماليه',
    value: 'دبلوم محاسبة ماليه',
  },
  {
    title: 'دبلوم محاسبة',
    value: 'دبلوم محاسبة',
  },
  {
    title: 'دبلوم متوسط',
    value: 'دبلوم متوسط',
  },
  {
    title: 'دبلوم مبيعات',
    value: 'دبلوم مبيعات',
  },
  {
    title: 'دبلوم لغة انجليزية',
    value: 'دبلوم لغة انجليزية',
  },
  {
    title: 'دبلوم كهرباء عامه',
    value: 'دبلوم كهرباء عامه',
  },
  {
    title: 'دبلوم كلية تقنية',
    value: 'دبلوم كلية تقنية',
  },
  {
    title: 'دبلوم كلية التقنية تخصص تجميل',
    value: 'دبلوم كلية التقنية تخصص تجميل',
  },
  {
    title: 'دبلوم قوى كربائية',
    value: 'دبلوم قوى كربائية',
  },
  {
    title: 'دبلوم قانون',
    value: 'دبلوم قانون',
  },
  {
    title: 'دبلوم في علوم الحاسب و التطبيقات-تخصص شبكات حاسب الي',
    value: 'دبلوم في علوم الحاسب و التطبيقات-تخصص شبكات حاسب الي',
  },
  {
    title: 'دبلوم في شبكات الحاسب الالي',
    value: 'دبلوم في شبكات الحاسب الالي',
  },
  {
    title: 'دبلوم في برامج الأوفيس',
    value: 'دبلوم في برامج الأوفيس',
  },
  {
    title: 'دبلوم في التقنيات المحاسبية',
    value: 'دبلوم في التقنيات المحاسبية',
  },
  {
    title: 'دبلوم فني صناعي',
    value: 'دبلوم فني صناعي',
  },
  {
    title: 'دبلوم علوم شريعة',
    value: 'دبلوم علوم شريعة',
  },
  {
    title: 'دبلوم عالي بتطوير البرمجيات',
    value: 'دبلوم عالي بتطوير البرمجيات',
  },
  {
    title: 'دبلوم صيانة الالات الميكانيكية بمعدل 3.74',
    value: 'دبلوم صيانة الالات الميكانيكية بمعدل 3.74',
  },
  {
    title: 'دبلوم شبكات حاسب',
    value: 'دبلوم شبكات حاسب',
  },
  {
    title: 'دبلوم سلامة وصحة مهنية - سيفتي',
    value: 'دبلوم سلامة وصحة مهنية - سيفتي',
  },
  {
    title: 'دبلوم سكرتير تنفيذي',
    value: 'دبلوم سكرتير تنفيذي',
  },
  {
    title: 'دبلوم سكرتارية طبية',
    value: 'دبلوم سكرتارية طبية',
  },
  {
    title: 'دبلوم دعم فني',
    value: 'دبلوم دعم فني',
  },
  {
    title: 'دبلوم حاسب الي',
    value: 'دبلوم حاسب الي',
  },
  {
    title: 'دبلوم حاسب 4.2',
    value: 'دبلوم حاسب 4.2',
  },
  {
    title: 'دبلوم تمريض',
    value: 'دبلوم تمريض',
  },
  {
    title: 'دبلوم تقنية الإنتاج',
    value: 'دبلوم تقنية الإنتاج',
  },
  {
    title: 'دبلوم تصميم جرافيك',
    value: 'دبلوم تصميم جرافيك',
  },
  {
    title: 'دبلوم تسويق',
    value: 'دبلوم تسويق',
  },
  {
    title: 'دبلوم تربوي',
    value: 'دبلوم تربوي',
  },
  {
    title: 'دبلوم تجارة',
    value: 'دبلوم تجارة',
  },
  {
    title: 'دبلوم برمجيات',
    value: 'دبلوم برمجيات',
  },
  {
    title: 'دبلوم إدارة مكتبية',
    value: 'دبلوم إدارة مكتبية',
  },
  {
    title: 'دبلوم إدارة الموارد البشرية',
    value: 'دبلوم إدارة الموارد البشرية',
  },
  {
    title: 'دبلوم انتاج',
    value: 'دبلوم انتاج',
  },
  {
    title: 'دبلوم المحاسبة المالية التطبيقية',
    value: 'دبلوم المحاسبة المالية التطبيقية',
  },
  {
    title: 'دبلوم الكلية التقنية',
    value: 'دبلوم الكلية التقنية',
  },
  {
    title: 'دبلوم التزين والتجميل',
    value: 'دبلوم التزين والتجميل',
  },
  {
    title: 'دبلوم ادارة مكتبيه',
    value: 'دبلوم ادارة مكتبيه',
  },
  {
    title: 'دبلوم . تخصص الكترونيات صناعية وتحكم',
    value: 'دبلوم . تخصص الكترونيات صناعية وتحكم',
  },
  {
    title: 'دبلوم',
    value: 'دبلوم',
  },
  {
    title: 'حكمة وطنية للملاكمة التايلنديةً',
    value: 'حكمة وطنية للملاكمة التايلنديةً',
  },
  {
    title: 'حزام أسود من درجة أولى في الكيك بو كسينغ',
    value: 'حزام أسود من درجة أولى في الكيك بو كسينغ',
  },
  {
    title: 'حراس الامن الخاص',
    value: 'حراس الامن الخاص',
  },
  {
    title: 'جراحة عامة',
    value: 'جراحة عامة',
  },
  {
    title: 'ثانويه',
    value: 'ثانويه',
  },
  {
    title: 'ثانويةعامة',
    value: 'ثانويةعامة',
  },
  {
    title: 'ثانوية عامة',
    value: 'ثانوية عامة',
  },
  {
    title: 'ثانوية الكامل',
    value: 'ثانوية الكامل',
  },
  {
    title: 'ثانوي',
    value: 'ثانوي',
  },
  {
    title: 'تقنية معلومات',
    value: 'تقنية معلومات',
  },
  {
    title: 'تقني سامي في الاعلام الآلي خيار البرمجة',
    value: 'تقني سامي في الاعلام الآلي خيار البرمجة',
  },
  {
    title: 'بلكوريس',
    value: 'بلكوريس',
  },
  {
    title: 'بكالوريوس هندسة العماره',
    value: 'بكالوريوس هندسة العماره',
  },
  {
    title: 'بكالوريوس هندسة البرمجيات. هندسة البرمجيات',
    value: 'بكالوريوس هندسة البرمجيات. هندسة البرمجيات',
  },
  {
    title: 'بكالوريوس مختبرات طبية',
    value: 'بكالوريوس مختبرات طبية',
  },
  {
    title: 'بكالوريوس محاسبة',
    value: 'بكالوريوس محاسبة',
  },
  {
    title: 'بكالوريوس في الشريعة والدراسات الإسلامية',
    value: 'بكالوريوس في الشريعة والدراسات الإسلامية',
  },
  {
    title: 'بكالوريوس في الشريعة',
    value: 'بكالوريوس في الشريعة',
  },
  {
    title: 'بكالوريوس في الاداب تخصص علم نفس',
    value: 'بكالوريوس في الاداب تخصص علم نفس',
  },
  {
    title: 'بكالوريوس رياضيات',
    value: 'بكالوريوس رياضيات',
  },
  {
    title: 'بكالوريوس رياض أطفال',
    value: 'بكالوريوس رياض أطفال',
  },
  {
    title: 'بكالوريوس دعوة وأصول الدين',
    value: 'بكالوريوس دعوة وأصول الدين',
  },
  {
    title: 'بكالوريوس تجاره شعبه محاسبه',
    value: 'بكالوريوس تجاره شعبه محاسبه',
  },
  {
    title: 'بكالوريوس تجاره',
    value: 'بكالوريوس تجاره',
  },
  {
    title: 'بكالوريوس أنظمة-قانون',
    value: 'بكالوريوس أنظمة-قانون',
  },
  {
    title: 'بكالوريوس أحياء',
    value: 'بكالوريوس أحياء',
  },
  {
    title: 'بكالوريوس الهندسة في الهندسة الميكانيكية(انتاج-تصنيع)',
    value: 'بكالوريوس الهندسة في الهندسة الميكانيكية(انتاج-تصنيع)',
  },
  {
    title: 'بكالوريوس اللغة الانجليزية والترجمة',
    value: 'بكالوريوس اللغة الانجليزية والترجمة',
  },
  {
    title: 'بكالوريوس الطب والجراحة',
    value: 'بكالوريوس الطب والجراحة',
  },
  {
    title: 'بكالوريوس - ادارة عامة (التنظيم والتطوير الإداري)',
    value: 'بكالوريوس - ادارة عامة (التنظيم والتطوير الإداري)',
  },
  {
    title: 'بكالوريس محاسبة',
    value: 'بكالوريس محاسبة',
  },
  {
    title: 'بكالوريس خدمة اجتماعية',
    value: 'بكالوريس خدمة اجتماعية',
  },
  {
    title: 'بكالوريس',
    value: 'بكالوريس',
  },
  {
    title: 'بكالورويس طب اسنان وجراحه الفم والفك',
    value: 'بكالورويس طب اسنان وجراحه الفم والفك',
  },
  {
    title: 'بكالريوس محاسبة',
    value: 'بكالريوس محاسبة',
  },
  {
    title: 'برنامج تدريبي بعنوان (إدارة التغيير)',
    value: 'برنامج تدريبي بعنوان (إدارة التغيير)',
  },
  {
    title: 'برنامج إعداد المراسلات',
    value: 'برنامج إعداد المراسلات',
  },
  {
    title: 'برنامج (شغل الوظائف)',
    value: 'برنامج (شغل الوظائف)',
  },
  {
    title: 'اول ثنوي',
    value: 'اول ثنوي',
  },
  {
    title: 'المواي طاي و الكيك بو كسينغ',
    value: 'المواي طاي و الكيك بو كسينغ',
  },
  {
    title: 'المتوسطة ( الكفاءه)',
    value: 'المتوسطة ( الكفاءه)',
  },
  {
    title: 'الكلية التقنية',
    value: 'الكلية التقنية',
  },
  {
    title: 'الكفاءة المتوسطة',
    value: 'الكفاءة المتوسطة',
  },
  {
    title: 'الشهاده الجامعية المتوسطه',
    value: 'الشهاده الجامعية المتوسطه',
  },
  {
    title: 'السنة الخامسة طب اسنان',
    value: 'السنة الخامسة طب اسنان',
  },
  {
    title: 'الثالثة ثانوية',
    value: 'الثالثة ثانوية',
  },
  {
    title: 'الأخلاق الإسلامية وآداب المهنة',
    value: 'الأخلاق الإسلامية وآداب المهنة',
  },
  {
    title: 'الاكاديمي بكالوريوس دراسات إسلامية',
    value: 'الاكاديمي بكالوريوس دراسات إسلامية',
  },
  {
    title: 'الاسعفات الاولية',
    value: 'الاسعفات الاولية',
  },
  {
    title: 'ابتدائي',
    value: 'ابتدائي',
  },
];

export default options;
