const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#r8dpdqk6qa)" fill="#4C4C55">
        <path d="M4 10h2v2H4v-2zM9 7H7v2h2V7zM7 10h2v2H7v-2zM12 7h-2v2h2V7z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm14 3H2v9h12V5z"
        />
      </g>
      <defs>
        <clipPath id="r8dpdqk6qa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
