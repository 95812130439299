import React from 'react';
import styled, { css } from 'styled-components';

import ListItem from '/imports/pdf/core/ui/atoms/ListItem.js';
import { View } from '/imports/pdf/core/ui/atoms/View';

const OrderedListItem = ({ children, num, BaseText, center, length, isRTL, first, bigPaddings, className }) => {
  return (
    <ListItem bigPaddings={bigPaddings} length={length} first={first} className={className}>
      {!center && (
        <Number>
          <BaseText isRTL={isRTL}>{num}.</BaseText>
        </Number>
      )}
      <BaseText isRTL={isRTL}>
        {center && `${num}.  `}
        {children}
      </BaseText>
    </ListItem>
  );
};

const Number = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}
`;

export default OrderedListItem;
