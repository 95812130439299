import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import Router from 'next/router';

import { getDomainFromHost, getRoute, isBlogDomain, getDefaultLanguage } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Link = ({ href, as, ...props }) => {
  const { locale } = useIntl();
  const { host } = useResponsive();
  const blog = isBlogDomain(host);

  let newAs = getRoute(as || href, locale, false, host);
  if (blog) {
    newAs = `/builder${newAs}`;
  }

  return <NextLink href={href} as={newAs} {...props} />;
};

export const Push = (href, locale = getDefaultLanguage(), as, options = {}) => {
  const { hostname } = window.location;
  const host = getDomainFromHost(hostname);
  let newAs = getRoute(as || href, locale, false, host);
  if (newAs.includes('/extension-landing')) {
    newAs = newAs.replace('/extension-landing', '');
  }
  const isBlog = isBlogDomain(host);
  if (isBlog && !newAs.includes('/builder')) {
    newAs = `/builder${newAs}`;
  }
  Router.push(href, newAs, { ...options });
};

Link.propTypes = {
  href: PropTypes.string,
  as: PropTypes.string,
  props: PropTypes.object,
};

export default Link;
