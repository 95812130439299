import DebounceLink from 'apollo-link-debounce';
import fetch from 'isomorphic-unfetch';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';

import env from '/env';

export let apolloClient = null;

function create(initialState, { getToken, getUserId, getAgentClientId }) {
  const isBrowser = typeof window !== 'undefined';

  const httpLink = new HttpLink({
    uri: `${env.BASE_API_GRAPHQL}/graphql`,
    credentials: 'same-origin',
    fetch: !isBrowser && fetch,
  });

  const coverLetterLink = new HttpLink({
    uri: `${env.COVER_LETTERS_ENDPOINT}/graphql`,
    credentials: 'same-origin',
    fetch: !isBrowser && fetch,
  });

  const authLink = setContext((_, { headers = {} }) => {
    const token = getToken();
    const userId = getUserId();
    const agentClientId = getAgentClientId();
    if (userId) {
      headers['x-user-id'] = userId;
    }
    if (agentClientId) {
      headers['x-user-id'] = agentClientId;
    }
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const cache = new InMemoryCache({ addTypename: true }).restore(initialState || {});

  cache.writeData({
    data: {
      expandedItem: '',
      savingStatus: 'SAVED',
      resumeDownloadFormat: 'pdf',
    },
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
            if (window && window?.Rollbar) {
              window.Rollbar?.error(`${path} | ${message}`);
            }
          });
        }

        if (networkError) {
          console.error(`[${networkError.name}]: Status: ${networkError.statusCode}: Message: ${networkError.message}`);
          if (window && window?.Rollbar) {
            window.Rollbar?.error(`${networkError.statusCode}-${networkError.message}`);
          }
        }
      }),
      new DebounceLink(1000),
      authLink.concat(
        ApolloLink.split(
          (operation) => operation.getContext().client === 'coverLetter', // Routes the query to the proper client
          coverLetterLink,
          httpLink,
        ),
      ),
    ]),
    cache,
    resolvers: {},
  });
}

function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}

export default initApollo;
