import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { getPageTitle, getPageDescription } from '/lib/helpers';
import { replaceString } from '/imports/core/ui/helpers';
import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';

// prettier-ignore
const Meta = ({ domain }) => {
  const { locale , t } = useIntl();
  const { getBrandName } =  useDomain();
  const brandName = getBrandName(true, domain)
  const title =  replaceString(t(getPageTitle(domain)), {Resumedone:brandName});
  const description = replaceString(t(getPageDescription(domain)), {Resumedone:brandName});
  const [content,setContent] = useState(description)
  const [contentTitle,setContentTitle] = useState(title)

  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('lang', locale);
      const updatedDescription = replaceString(t(getPageDescription(domain)), {
        Resumedone: brandName,
      });
      const updatedTitle = replaceString(t(getPageTitle(domain)), {
        Resumedone: brandName,
      });
      setContent(updatedDescription)
      setContentTitle(updatedTitle)
  }, [locale]);
  return(
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable = no"/>
      <meta name="google-site-verification" content="oCfOSAYFCdO0iwkEgtJZDexCejSnC1zLGe4Fs2aCUl4" />
      <title>{contentTitle}</title>
      <meta name="description" lang={locale} content={content}/>
      <link rel="titleapple-touch-icon" sizes="120x120" href="/static/apple-touch-icon-precomposed.png"/>
    </Head>
  );
}

Meta.propTypes = {
  domain: PropTypes.string,
};

Meta.displayName = 'Meta';

export default Meta;
