import styled, { css } from 'styled-components';

// Avoids
export const UnbreakableView = styled.div`
  display: block;
  break-inside: avoid;
  page-break-inside: avoid;

  ${({ $atsresume, theme: { convertPx } }) =>
    $atsresume &&
    css`
      margin-bottom: ${convertPx(4)};
    `}

  ${(p) =>
    p.isBreakable &&
    css`
      break-inside: auto;
      page-break-inside: auto;
    `}
`;

export default UnbreakableView;
