const TemplateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04 1.597A2.01 2.01 0 0 0 1 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H3a2 2 0 0 0-1.96 1.597zM3 2h10v12H3V2zm4 4H5V4h2v2zm1 0h3V5H8v1zm3 1v1H5V7h6zm0 3V9H5v1h6zm-2 1v1H5v-1h4z"
        fill="#4C4C55"
      />
    </svg>
  );
};

export default TemplateIcon;
